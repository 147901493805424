.gap-20 {
  gap: 20px;
}
.threat-hunting-fieldset {
  padding: 10px;
  border-width: 1px;
  border-radius: 4px;
  border-style: groove;
  margin-top: -0.6em;
  /* height: 55px; */
}
.threat-hunting-legend {
  float: none;
  width: unset;
  padding-left: 4px;
  padding-inline-end: 10px;
  font-size: 0.75em;
  font-size: 12px;
  margin-bottom: -0.7em;
}
.headerAddBatch {
  display: flex;
  gap: 30px;
  margin-left: 30px;
}

.configure_header {
  display: flex;
  gap: 15%;
}
.action_right {
  align-self: flex-end;
  margin-left: auto;
  margin-right: 100px;
}
.header_configure_accordian {
  display: flex;
  gap: 15%;
  margin-left: 25px;
}
.wd-100 {
  /* width: 100px; */
  width: 25%;
  /* overflow: auto; */
  word-wrap: break-word;
}
.wd-150 {
  /* width: 150px; */
  width: 25%;
  word-wrap: break-word;

  /* overflow: auto; */
}
.configure-inner-table-header {
  display: flex;
  justify-content: space-between;
}
.configure-inner-table-border {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.threat-hunting-alert-actions {
  gap: 15px;
  flex-direction: row-reverse;
  width: 100%;
  align-items: end;
  justify-content: end;
  padding-right: 15px;
}
.threat-hunting-alert-title {
  text-align: start;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;

  color: #ffffff;
}

.threat-hunting-alert-content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 27px;
  /* identical to box height */
  text-align: start;
  letter-spacing: 0.05em;

  color: rgba(255, 255, 255, 0.4);
}
.threat-hunting-alert-close-btn {
  font-size: 14px;
  margin-top: 12px;
  margin-right: 12px;
  background-color: white;
  border-radius: 20px;
  color: black;
}
.test_details {
  background-color: rgba(255, 255, 255, 0.05);
  width: 90%;
}

.test_details_table_width {
  padding-top: 20px;
  width: 180px;
}
.console_detail {
  cursor: pointer;
  min-height: 60px;
  white-space: nowrap;
  overflow: hidden;
  /* color: #ffffff; */
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 20px;
  text-overflow: ellipsis;
}
.success-text {
  color: #01b8aa;
}
.simple_result {
  text-overflow: none;
  overflow: visible;
  white-space: normal;
}
h3 {
  font-weight: 700;
}
.link-color {
  color: #0177fb;
}
.reccurence-modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: #2a2e31;
  color: white;
  border-radius: 5px;
  box-shadow: 24px;
  padding: 20px;
}
.repeat-number-width {
  width: 30%;
}
.subline-dropdown {
  font-size: 12px;
}

.white-btn {
  background-color: #d8eaff;
  border: 1px solid #d8eaff;
  border-radius: 4px;
  color: #0177fb;
  text-align: center;
}

.blue-btn {
  background-color: #0177fb;
  border: 1px solid #0177fb;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
}
.pd-l10 {
  padding-left: 10px;
}
.pd-r10 {
  padding-right: 10px;
}
.mr-l10 {
  margin-left: 10px;
}
.float-right {
  float: right;
}
.mr-t10 {
  margin-top: 10px;
}

.thread-hunting-table {
  /* display: flex !important; */
  /* max-width: 700px; */
  width: 100%;
  overflow: auto;
  padding-left: 50px;
}

.text-wrap {
  word-wrap: break-word;
}
.overflow-auto {
  overflow: auto;
}
