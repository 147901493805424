@import "../css/fonts.css";
body {
  font-family: Inter-Regular !important;
}

:root {
  --pureWhite: #ffffff;
  --whiteShade-100: #f7f9fb;
  --whiteShade-200: #151a2c;
  --darkBlack: #1f1f1f;
  --grey: #1f1f1f66;
  --green: #01b8aa;

  --darkblue-100: #151a2c;
  --darkblue-200: #262c3f;
  --darkblue-300: #151a2c;
  --white: #ffffff;
  --lightblue: #262c3f;
}
.card-icon {
  font-size: 40px;
  margin-top: 5px;
}
.card-text-white h5 {
  font-size: 12px;
  font-family: Inter-Regular !important;
  font-weight: 600;
  // text-align: center;
}

.form-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 12px;
  font-family: Inter-Regular !important;
}

.device-heading {
  color: rgba(255, 255, 255, 0.4);
  font-family: Inter-Regular;
  // font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.subline-dropdown {
  color: rgba(255, 255, 255, 0.4);
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.font-size10 {
  font-size: 10px;
}
.h32 {
  height: 32px;
}
.font-size12 {
  font-size: 12px;
}
.font-size14 {
  font-size: 14px;
}
.font-size16 {
  font-size: 16px;
}
.font-size18 {
  font-size: 18px;
}
.font-size20 {
  font-size: 20px;
}
.font-size22 {
  font-size: 22px;
}
.font-size32 {
  font-size: 32px;
}
.sidebar-icon {
  display: none;
}
.sidebar-icon1 {
  display: none;
}
.list-device {
  display: none;
  margin: 10px 0px;
  color: white;
}
.pay-m-block {
  padding: 15px;
  max-width: 279px;
  width: 100%;
  margin-top: 15px;
  ul {
    padding: 0px;
    margin: 0px;
    li {
      list-style-type: none;
      border-radius: 4px;
      padding: 15px;
    }
  }
}
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: -1px 5px;
}

.bg-green {
  background-color: green;
}
.bg-red {
  background-color: red;
}
.bg-orange {
  background-color: orange;
}
.bg-blue {
  background-color: #0177fb;
}
.blue-text {
  color: #0177fb;
}
.order-card {
  max-width: 263px;
  background: #2c3033;
  padding: 10px;
}
.ant-modal-body {
  max-height: 80vh;
  // overflow: auto;
}
.mr-t0 {
  margin-top: 0px;
}
.mr-t10 {
  margin-top: 10px;
}
.mr-t20 {
  margin-top: 20px;
}
.mr-t30 {
  margin-top: 30px;
}
.mr-t40 {
  margin-top: 40px;
}
.mr-t50 {
  margin-top: 50px;
}
.mr-t60 {
  margin-top: 60px;
}
.mr-t70 {
  margin-top: 70px;
}
.mr-t80 {
  margin-top: 80px;
}
.mr-t90 {
  margin-top: 90px;
}
.mr-t100 {
  margin-top: 100px;
}
.mr-r0 {
  margin-right: 0px;
}
.mr-t0 {
  margin-top: 0px;
}
.mr-t10 {
  margin-top: 10px;
}
.mr-t20 {
  margin-top: 20px;
}
.mr-t30 {
  margin-top: 30px;
}
.mr-t40 {
  margin-top: 40px;
}
.mr-t50 {
  margin-top: 50px;
}
.mr-t60 {
  margin-top: 60px;
}
.mr-t70 {
  margin-top: 70px;
}
.mr-t80 {
  margin-top: 80px;
}
.mr-t90 {
  margin-top: 90px;
}
.mr-t100 {
  margin-top: 100px;
}
.mr-r0 {
  margin-right: 0px;
}

.mr-b10 {
  margin-bottom: 10px;
}
.mr-b20 {
  margin-bottom: 20px;
}
.mr-b30 {
  margin-bottom: 30px;
}
.mr-b40 {
  margin-bottom: 40px;
}
.mr-b50 {
  margin-bottom: 50px;
}
.mr-b60 {
  margin-bottom: 60px;
}
.mr-b70 {
  margin-bottom: 70px;
}
.mr-b80 {
  margin-bottom: 80px;
}
.mr-b90 {
  margin-bottom: 90px;
}
.mr-b100 {
  margin-bottom: 100px;
}
.mr-l0 {
  margin-left: 0px;
}
.mr-l10 {
  margin-left: 10px;
}
.mr-l20 {
  margin-left: 20px;
}
.mr-l30 {
  margin-left: 30px;
}
.mr-l40 {
  margin-left: 40px;
}
.mr-l50 {
  margin-left: 50px;
}
.mr-l60 {
  margin-left: 60px;
}
.mr-l70 {
  margin-left: 70px;
}
.mr-l80 {
  margin-left: 80px;
}
.mr-l90 {
  margin-left: 90px;
}
.mr-l100 {
  margin-left: 100px;
}
.pd0 {
  padding: 0px;
}
.pd10 {
  padding: 10px;
}
.pd20 {
  padding: 20px;
}
.pd30 {
  padding: 30px;
}
.pd40 {
  padding: 40px;
}
.pd50 {
  padding: 50px;
}
.pd60 {
  padding: 60px;
}
.pd70 {
  padding: 70px;
}
.pd80 {
  padding: 80px;
}
.pd90 {
  padding: 90px;
}
.pd100 {
  padding: 100px;
}
.pd-t0 {
  padding-top: 0px;
}
.pd-t10 {
  padding-top: 10px;
}
.pd-t20 {
  padding-top: 20px;
}
.pd-t30 {
  padding-top: 30px;
}
.pd-t40 {
  padding-top: 40px;
}
.pd-t50 {
  padding-top: 50px;
}
.pd-t60 {
  padding-top: 60px;
}
.pd-t70 {
  padding-top: 70px;
}
.pd-t80 {
  padding-top: 80px;
}
.pd-t90 {
  padding-top: 90px;
}
.pd-t100 {
  padding-top: 100px;
}
.pd-r0 {
  padding-right: 0px;
}
.pd-r10 {
  padding-right: 10px;
}
.pd-r20 {
  padding-right: 20px;
}
.pd-r30 {
  padding-right: 30px;
}
.pd-r40 {
  padding-right: 40px;
}
.pd-r50 {
  padding-right: 50px;
}
.pd-r60 {
  padding-right: 60px;
}
.pd-r70 {
  padding-right: 70px;
}
.pd-r80 {
  padding-right: 80px;
}
.pd-r90 {
  padding-right: 90px;
}
.pd-r100 {
  padding-right: 100px;
}
.pd-r170 {
  padding-right: 170px;
}
.pd-b0 {
  padding-bottom: 0px;
}
.pd-b10 {
  padding-bottom: 10px;
}
.pd-b20 {
  padding-bottom: 20px;
}
.pd-b30 {
  padding-bottom: 30px;
}
.pd-b40 {
  padding-bottom: 40px;
}
.pd-b50 {
  padding-bottom: 50px;
}
.pd-b60 {
  padding-bottom: 60px;
}
.pd-b70 {
  padding-bottom: 70px;
}
.pd-b80 {
  padding-bottom: 80px;
}
.pd-b90 {
  padding-bottom: 90px;
}
.pd-b100 {
  padding-bottom: 100px;
}
.pd-l0 {
  padding-left: 0px;
}

.pd-l20 {
  padding-left: 20px;
}
.pd-l30 {
  padding-left: 30px;
}

.pd-l50 {
  padding-left: 50px;
}
.pd-l60 {
  padding-left: 60px;
}
.pd-l70 {
  padding-left: 70px;
}
.pd-l80 {
  padding-left: 80px;
}
.pd-l90 {
  padding-left: 90px;
}
.pd-l100 {
  padding-left: 100px;
}
.delete-message {
  font-family: Inter-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.text-red {
  color: #dc3545;
}
.custom-cancel-button {
  background-color: #d8eaff !important;
  color: #0177fb !important;
}
.custom-confirm-button {
  background-color: #dc3545 !important;
  color: #ffffff !important;
  outline: none;
}
.dark-theme {
  $color_1: #ffffff;
  $color_2: #dc3545;
  $color_3: #01b8aa;
  $color_4: #fe7750;
  $color_5: #f0c555;
  $color_6: #0177fb;
  $color_7: rgba(255, 255, 255, 0.4);
  $color_8: #212121;
  $color_9: #a5b3cd;
  $color_10: rgba(255, 255, 255, 1);
  $color_11: var(--white-40, rgba(255, 255, 255, 0.4));
  $color_12: var(--white-100, #fff);
  $color_13: inherit;
  $color_14: #333;
  $color_15: #fff;
  $color_16: #d32f2f;
  $color_17: #d1cccc;
  $color_18: #ffffff66;
  $color_19: green;
  $font-family_1: Inter-Black;
  $font-family_2: Inter-Bold;
  $font-family_3: Inter-ExtraBold;
  $font-family_4: Inter-Light;
  $font-family_5: Inter-Medium;
  $font-family_6: Inter-Regular;
  $font-family_7: Inter-Thin;
  $font-family_8: "Inter";
  $font-family_9: "Roboto", "Helvetica", "Arial", sans-serif;
  $background-color_1: transparent;
  $background-color_2: #0177fb;
  $background-color_3: #d8eaff;
  $background-color_4: #dc3545;
  $background-color_5: #212121;
  $background-color_6: #212528;
  $background-color_7: #2a2e31;
  $background-color_8: #01b8aa;
  $background-color_9: #fe7750;
  $background-color_10: rgba(255, 255, 255, 0.05);
  $background-color_11: inherit;
  $background-color_12: #212529;
  $background-color_13: green;
  $background-color_14: red;
  $background-color_15: gray;
  $background-color_16: #333;
  $border-color_1: #1677ff;
  $border-color_2: rgba(255, 255, 255, 0.1);
  $border-color_3: #6b6b6b;
  .outline-label-1 {
    background: #2a2e31;
  }
  .card-text-white h5 {
    color: #fff;
  }
  .card-icon {
    color: $color_8;
  }
  .nested-table-rows {
    background: #4c4f52;
  }
  .highcharts-axis-labels text {
    fill: white !important;
  }
  .disabledclass .ruleset-add label {
    color: #959795 !important;
  }
  .highcharts-legend-item text {
    fill: white !important;
  }
  .card-icon {
    color: $color_15;
  }

  .form-label {
    color: var(--white);
  }

  /* all margin */
  /* margin top */
  /* margin right */
  /* margin bottom */
  /* margin left */
  /* padding  */
  /* padding top */
  /* padding right */
  /* padding bottom */
  /* padding left */
  /* Login css  */
  /* login media css  */
  /* Sidebar css */
  /* Track */
  /* Handle */
  /* Track */
  /* Handle */
  /* New CSS  */
  /* .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.css-mli2yg-MuiFormControl-root-MuiTextField-root .MuiInputLabel-root,
.MuiFormLabel-root {
  font-family: Inter-Regular;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
}

.css-14lo706 > span {
  color: rgba(255, 255, 255, 0.4);
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(18px, -7px) scale(0.85);
  -webkit-transform: translate(18px, -7px) scale(0.85);
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  font-size: 12px;
  color: #ffffff !important;
  font-family: Inter-Bold;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(15px, 13px) scale(1);
}

.css-mli2yg-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
  font-family: Inter-Regular;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #ffffff !important;
  font-size: 12px;
  font-family: Inter-Bold;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 11px 14px !important;
}

.css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.1);
} */
  /* .MuiSelect-icon path {
  display: none;
}

.MuiSelect-icon {
  background: url("../images/w-d-icon.png") no-repeat center;
} */
  /* .ant-table {
    background-color: #2a2e31;
  } */
  /* .ant-table-thead th {
  color: #ffffff !important;
  background-color: #2a2e31 !important;
  border: none !important;
} */
  /* input {
    color: #ffffff !important;
    height: 12px !important;
  } */
  /* .MuiFormLabel-root {
  padding: 0.6rem 0.75rem;
  color: rgba(255, 255, 255, 0.4);
  font-family: Inter-Regular;
  font-size: 14px;
  padding: 0px;
  background-color: #2a2e31;
  height: auto;
  opacity: 1;
} */
  /* Define styles for the Select component */
  /* Define styles for the input element */
  /* .ant-select-selector input {
    border: none !important;
    outline: none !important;
  } */
  /* Define styles for the control wrapper */
  /* Define styles for the focused state of the control wrapper */
  /* .ant-select-focused .ant-select-selector {
    border-color: #3f51b5 !important;
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2) !important;
  } */
  /* Define styles for the selected option */
  /* .ant-select-selection-item {
    color: #3f51b5 !important;
  } */
  /* Define styles for the menu */
  /* Define styles for the menu options */
  /* Define styles for the selected menu option */
  /* .ant-select-item-option-selected {
    background-color: #3f51b5 !important;
    color: #fff !important;
  } */
  /* Add more custom styles as needed */
  /* .jodit-container:not(.jodit_inline) {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
} */
  /* .bg-blue {
  background-color: #0177fb;
} */
  .footer {
    background: $background-color_7;
  }
  .ruleset-add-btn,
  .container-add {
    color: white !important;
  }
  .btn-secondary {
    &:hover {
      background-color: $background-color_1;
    }
    &:focus {
      background-color: $background-color_1;
    }
  }
  a {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    text-decoration: none;
    opacity: 1;
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
  }
  button {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    text-decoration: none;
    opacity: 1;
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
  }
  input {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    text-decoration: none;
    opacity: 1;
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
  }

  .ant-radio-wrapper-disabled span {
    color: #9697a1;
  }
  h1 {
    margin: 0px;
    padding: 0px;
  }
  h2 {
    margin: 0px;
    padding: 0px;
  }
  h3 {
    margin: 0px;
    padding: 0px;
  }
  h4 {
    margin: 0px;
    padding: 0px;
  }
  h5 {
    margin: 0px;
    padding: 0px;
  }
  h6 {
    margin: 0px;
    padding: 0px;
  }
  p {
    margin: 0px;
    padding: 0px;
  }
  .mr0 {
    margin: 0px;
  }
  .mr10 {
    margin: 10px;
  }
  .mr20 {
    margin: 20px;
  }
  .mr30 {
    margin: 30px;
  }
  .mr40 {
    margin: 40px;
  }
  .mr50 {
    margin: 50px;
  }
  .mr60 {
    margin: 60px;
  }
  .mr70 {
    margin: 70px;
  }
  .mr80 {
    margin: 80px;
  }
  .mr90 {
    margin: 90px;
  }
  .mr100 {
    margin: 100px;
  }

  .mr-r10 {
    margin-right: 10px;
  }
  .mr-r20 {
    margin-right: 20px;
  }
  .mr-r30 {
    margin-right: 30px;
  }
  .mr-r40 {
    margin-right: 40px;
  }
  .mr-r50 {
    margin-right: 50px;
  }
  .mr-r60 {
    margin-right: 60px;
  }
  .mr-r70 {
    margin-right: 70px;
  }
  .mr-r80 {
    margin-right: 80px;
  }
  .mr-r90 {
    margin-right: 90px;
  }
  .mr-r100 {
    margin-right: 100px;
  }
  .mr-b0 {
    margin-bottom: 0px;
  }
  .mr-b10 {
    margin-bottom: 10px;
  }
  .mr-b20 {
    margin-bottom: 20px;
  }
  .mr-b30 {
    margin-bottom: 30px;
  }
  .mr-b40 {
    margin-bottom: 40px;
  }
  .mr-b50 {
    margin-bottom: 50px;
  }
  .mr-b60 {
    margin-bottom: 60px;
  }
  .mr-b70 {
    margin-bottom: 70px;
  }
  .mr-b80 {
    margin-bottom: 80px;
  }
  .mr-b90 {
    margin-bottom: 90px;
  }
  .mr-b100 {
    margin-bottom: 100px;
  }
  .mr-l0 {
    margin-left: 0px;
  }
  .mr-l10 {
    margin-left: 10px;
  }
  .mr-l20 {
    margin-left: 20px;
  }
  .mr-l30 {
    margin-left: 30px;
  }
  .mr-l40 {
    margin-left: 40px;
  }
  .mr-l50 {
    margin-left: 50px;
  }
  .mr-l60 {
    margin-left: 60px;
  }
  .mr-l70 {
    margin-left: 70px;
  }
  .mr-l80 {
    margin-left: 80px;
  }
  .mr-l90 {
    margin-left: 90px;
  }
  .mr-l100 {
    margin-left: 100px;
  }
  .pd0 {
    padding: 0px;
  }
  .pd10 {
    padding: 10px;
  }
  .pd20 {
    padding: 20px;
  }
  .pd30 {
    padding: 30px;
  }
  .pd40 {
    padding: 40px;
  }
  .pd50 {
    padding: 50px;
  }
  .pd60 {
    padding: 60px;
  }
  .pd70 {
    padding: 70px;
  }
  .pd80 {
    padding: 80px;
  }
  .pd90 {
    padding: 90px;
  }
  .pd100 {
    padding: 100px;
  }
  .pd-t0 {
    padding-top: 0px;
  }
  .pd-t10 {
    padding-top: 10px;
  }
  .pd-t20 {
    padding-top: 20px;
  }
  .pd-t30 {
    padding-top: 30px;
  }
  .pd-t40 {
    padding-top: 40px;
  }
  .pd-t50 {
    padding-top: 50px;
  }
  .pd-t60 {
    padding-top: 60px;
  }
  .pd-t70 {
    padding-top: 70px;
  }
  .pd-t80 {
    padding-top: 80px;
  }
  .pd-t90 {
    padding-top: 90px;
  }
  .pd-t100 {
    padding-top: 100px;
  }
  .pd-r0 {
    padding-right: 0px;
  }
  .pd-r10 {
    padding-right: 10px;
  }
  .pd-r20 {
    padding-right: 20px;
  }
  .pd-r30 {
    padding-right: 30px;
  }
  .pd-r40 {
    padding-right: 40px;
  }
  .pd-r50 {
    padding-right: 50px;
  }
  .pd-r60 {
    padding-right: 60px;
  }
  .pd-r70 {
    padding-right: 70px;
  }
  .pd-r80 {
    padding-right: 80px;
  }
  .pd-r90 {
    padding-right: 90px;
  }
  .pd-r100 {
    padding-right: 100px;
  }
  .pd-r170 {
    padding-right: 170px;
  }
  .pd-b0 {
    padding-bottom: 0px;
  }
  .pd-b10 {
    padding-bottom: 10px;
  }
  .pd-b20 {
    padding-bottom: 20px;
  }
  .pd-b30 {
    padding-bottom: 30px;
  }
  .pd-b40 {
    padding-bottom: 40px;
  }
  .pd-b50 {
    padding-bottom: 50px;
  }
  .pd-b60 {
    padding-bottom: 60px;
  }
  .pd-b70 {
    padding-bottom: 70px;
  }
  .pd-b80 {
    padding-bottom: 80px;
  }
  .pd-b90 {
    padding-bottom: 90px;
  }
  .pd-b100 {
    padding-bottom: 100px;
  }
  .pd-l0 {
    padding-left: 0px;
  }

  .pd-l20 {
    padding-left: 20px;
  }
  .pd-l30 {
    padding-left: 30px;
  }
  .pd-l40 {
    padding-left: 40px;
  }
  .pd-l50 {
    padding-left: 50px;
  }
  .pd-l60 {
    padding-left: 60px;
  }
  .pd-l70 {
    padding-left: 70px;
  }
  .pd-l80 {
    padding-left: 80px;
  }
  .pd-l90 {
    padding-left: 90px;
  }
  .pd-l100 {
    padding-left: 100px;
  }
  .Inter-Black {
    font-family: $font-family_1;
  }
  .Inter-Bold {
    font-family: $font-family_2;
  }
  .Inter-ExtraBold {
    font-family: $font-family_3;
  }
  .Inter-Light {
    font-family: $font-family_4;
  }
  .Inter-Medium {
    font-family: $font-family_5;
  }
  .Inter-Regular {
    font-family: $font-family_6;
  }
  .Inter-Thin {
    font-family: $font-family_7;
  }

  .lh17 {
    line-height: 17px;
  }
  .h46 {
    height: 46px;
  }

  .color-white {
    color: $color_1;
  }
  .color-red {
    color: $color_2;
  }
  .color-green {
    color: $color_3;
  }
  .color-orange {
    color: $color_4;
  }
  .color-yellow {
    color: $color_5;
  }
  .color-blue {
    color: $color_6;
  }
  .blue-btn {
    background-color: $background-color_2;
    border: 1px solid #0177fb;
    border-radius: 4px;
    color: $color_1;
    text-align: center;
    &:hover {
      color: $color_6;
      background-color: $background-color_1;
    }
  }
  .gray-bor-btn {
    background-color: $background-color_1;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    text-align: center;
    color: $color_7;
  }

  .red-btn {
    background-color: $background-color_4;
    border: 1px solid #dc3545;
    border-radius: 4px;
    color: $color_1;
    text-align: center;
  }
  .black-trans-btn {
    background-color: $background-color_1;
    border: 1px solid #212121;
    border-radius: 50px;
    font-size: 16px;
    color: $color_8;
    text-align: center;
    &:hover {
      background-color: $background-color_5;
      color: $color_1;
    }
  }
  .trans-btn {
    background: transparent;
    border: 0px;
    padding: 0px;
    line-height: 0px;
  }
  .file-icon {
    line-height: 0px;
  }
  .c-icon {
    line-height: 0px;
  }
  .login-wrap {
    background-color: $background-color_6;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  .lt-logo-block {
    img {
      max-width: 105px;
      width: 100%;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }
  .login-box {
    max-width: 630px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 27em);
      height: 100vh;
      background: linear-gradient(
        269.36deg,
        rgba(42, 46, 49, 0.4) 4.22%,
        rgba(42, 46, 49, 0) 109.7%
      );
      right: 0px;
      top: 0px;
      transform: rotate(-13.5deg) translateX(-7px) translateY(0px) skewX(44deg)
        skewY(-18.4deg);
      -webkit-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      -moz-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      -ms-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      -o-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      z-index: 1;
      transform-origin: top right;
      -webkit-transform-origin: top right;
      -moz-transform-origin: top right;
      -ms-transform-origin: top right;
      -o-transform-origin: top right;
      pointer-events: none;
    }
    h5 {
      font-size: 16px;
      color: $color_7;
      font-family: $font-family_6;
      text-align: center;
    }
    h3 {
      font-size: 24px;
      color: $color_1;
      font-family: $font-family_2;
      text-align: center;
    }
  }
  .l-inn-content {
    width: 100%;
    background: #2a2e31;
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 70px 60px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  .l-form {
    max-width: 300px;
    width: 100%;
    margin: 40px auto 0px;
  }
  .cus-f-group {
    label.f-input-title {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
      position: absolute;
    }
    .cus-from-control {
      height: 46px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      background-color: $background-color_1;
      font-size: 12px;
      font-family: $font-family_6;
      color: $color_1;
    }
  }
  .floting-input {
    position: relative;
    label {
      position: absolute;
      top: 0px;
      left: 10px;
      padding: 0px 10px;
      background-color: $background-color_7;
      top: -9px;
    }
  }
  .f-r-block {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .r-cus-check {
    font-size: 12px;
    color: $color_7;
    font-family: $font-family_6;
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
      display: none;
      &:checked {
        ~ {
          span {
            background: #0177fb;
            border: 1px solid #0177fb;
            &::after {
              content: "L";
              position: absolute;
              width: 100%;
              height: 100%;
              font-size: 11px;
              font-family: $font-family_5;
              color: $color_1;
              transform: scaleX(-1) rotate(-48deg);
              display: inline-flex;
              align-items: center;
              top: -1px;
              justify-content: center;
              left: 0px;
            }
          }
        }
      }
    }
    span {
      width: 16px;
      height: 16px;
      display: block;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 2px;
      position: relative;
    }
  }
  .in-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 17px;
    background-color: $background-color_1;
    border: 0px;
    padding: 0px;
    line-height: 0px;
  }
  .top-bg-line {
    height: 4px;
    width: 38vw;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(37deg) translateX(12vw);
    transform-origin: center;
    background: linear-gradient(
      289.53deg,
      rgba(1, 119, 251, 0.3) 2.87%,
      rgba(1, 119, 251, 0) 99.44%
    );
    pointer-events: none;
  }
  .top-gg-line {
    height: 4px;
    width: 53vw;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(37deg) translateX(12vw);
    transform-origin: center;
    background: linear-gradient(
      289.6deg,
      #2a2e31 5.38%,
      rgba(42, 46, 49, 0) 99.45%
    );
    pointer-events: none;
  }
  .bottom-bg-line {
    height: 4px;
    width: 52vw;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(37deg) translateX(-14vw);
    transform-origin: center;
    background: -moz-linear-gradient(
      left,
      rgba(1, 119, 251, 0) 0%,
      rgba(1, 119, 251, 0) 19%,
      rgba(1, 119, 251, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(1, 119, 251, 0) 0%,
      rgba(1, 119, 251, 0) 19%,
      rgba(1, 119, 251, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(1, 119, 251, 0) 0%,
      rgba(1, 119, 251, 0) 19%,
      rgba(1, 119, 251, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000177fb', endColorstr='#0177fb', GradientType=1);
    pointer-events: none;
  }
  .bottom-gg-line {
    height: 4px;
    width: 38vw;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(37deg) translateX(-14vw);
    transform-origin: center;
    background: linear-gradient(
      289.6deg,
      #2a2e31 5.38%,
      rgba(42, 46, 49, 0) 99.45%
    );
    pointer-events: none;
  }
  .l-s-text {
    font-size: 14px;
    color: $color_7;
    line-height: 17px;
    letter-spacing: 0.05em;
  }
  .l-b-text {
    font-size: 12px;
    color: $color_7;
    line-height: 15px;
    letter-spacing: 0.05em;
  }
  .sidebar-wrap {
    max-width: 223px;
    width: 100%;
    background: #2a2e31;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 999;
  }
  .s-top-logo {
    max-width: 72px;
    height: auto;
    width: 100%;
    margin: 30px auto;
    img {
      width: 100%;
    }
  }
  .s-nav {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    &::-webkit-scrollbar {
      width: 0px;
    }
    h5 {
      font-size: 12px;
      color: $color_9;
      font-family: $font-family_6;
      text-transform: uppercase;
    }
  }
  .s-bm-logo {
    padding: 20px;
  }
  .nav-links-wrap {
    .dropdown-menu {
      position: static !important;
      transform: translate(0) !important;
      transition: all 0.5s linear 0s;
    }
    .dropdown-menu.show {
      transition: all 0.5s linear 0s;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        margin: 24px 0px;
        a {
          font-size: 14px;
          color: $color_9;
          font-family: $font-family_6;
          display: flex;
          align-items: center;
          .snav-icon {
            width: 20px;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
          }
        }
        &:hover {
          a {
            color: $color_6;
            stroke: #0177fb;
            svg {
              path {
                color: $color_6;
                stroke: #0177fb;
              }
            }
          }
        }
        &:first-child {
          &:hover {
            a {
              svg {
                path {
                  // fill: #0177fb;
                }
              }
            }
          }
        }
      }
    }
  }
  .top-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 24px 20px;
    padding-left: 225px;
    background-color: $background-color_6;
    z-index: 999;
  }
  .head-pro-block {
    display: flex;
    align-items: center;
  }
  .pro-drop-box {
    background-color: $background-color_1;
    border: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
  }
  .user-pro-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 30px;
      max-height: 30px;
      object-fit: cover;
    }
  }
  .user-pro-info {
    line-height: 7px;
    text-align: left;
    padding-left: 10px;
    h4 {
      font-size: 12px;
      color: $color_1;
      font-family: $font-family_2;
    }
    a {
      font-size: 10px;
      color: $color_1;
      font-family: $font-family_6;
    }
  }
  .dropdown-toggle {
    &::after {
      margin-left: 14px;
    }
  }
  .ntification-block {
    margin-right: 25px;
  }
  .body-wrap {
    padding-left: 225px;
    padding-top: 80px;
  }
  .body-r-container {
    padding: 10px 20px 20px;
  }
  .table-head {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    padding: 8px;
  }
  .t-filter-search {
    input {
      border-radius: 4px;
      border: 0px;
      height: 32px;
      max-width: 174px;
      width: 100%;
      font-size: 12px;
      color: $color_10;
      padding: 10px 10px 10px 35px;
      font-family: $font-family_6;
      background-color: $background-color_6 !important;
      padding: 12px 0px !important;
      &::placeholder {
        color: $color_7;
      }
      &:-ms-input-placeholder {
        color: $color_7;
      }
      &::-ms-input-placeholder {
        color: $color_7;
      }
    }
    .anticon {
      svg {
        fill: white !important;
      }
    }
    span {
      background-color: $background-color_6 !important;
      border: none !important;
      border-radius: 2px;
    }
    border: none !important;
  }
  .t-filter-block {
    button {
      background: transparent;
      border: 0px;
      padding: 0px;
    }
  }
  .cus-dark-table {
    table {
      width: 100%;
      thead {
        tr {
          th {
            font-size: 12px;
            color: $color_7;
            font-family: $font-family_6;
            font-weight: normal;
            padding: 10px;
            white-space: nowrap;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 12px;
            font-family: $font-family_6;
            color: $color_1 !important;
            padding: 5px 10px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          &:last-child {
            td {
              border-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .status-text {
    font-size: 12px;
    font-family: $font-family_6;
    position: relative;
    display: flex;
    align-items: center;
    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .green-status {
    color: $color_3;
    &::before {
      background-color: $background-color_8;
    }
  }
  .red-status {
    color: $color_4;
    &::before {
      background-color: $background-color_9;
    }
  }
  .action-btn {
    button {
      background: transparent;
      border: 0px;
    }
  }
  .cus-pagination {
    display: flex;
    justify-content: flex-end;
  }
  .page-item.disabled {
    .page-link {
      background: transparent;
      svg {
        path {
          fill: #484848;
        }
      }
    }
  }
  .page-link {
    background-color: $background-color_1;
    border: 0px;
    font-size: 12px;
    color: $color_1;
    font-family: $font-family_6;
    border-radius: 8px;
    &:hover {
      background-color: $background-color_10;
      color: $color_1;
    }
  }
  .pro-menu {
    a {
      font-size: 12px;
      color: $color_1;
      font-family: $font-family_2;
      display: flex;
      align-items: center;
      padding: 15px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      .d-pro-icon {
        margin-right: 10px;
      }
    }
    li {
      &:first-child {
        a {
          padding-top: 0px;
          border-top: 0px;
        }
      }
      &:last-child {
        a {
          padding-bottom: 0px;
          border-bottom: 0px;
        }
      }
    }
    padding: 0px;
    background: #2a2e31;
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    min-width: 12.5rem;
  }
  .dropdown-item.active {
    background-color: $background-color_1;
    color: $color_1;
  }
  .dropdown-item {
    &:active {
      background-color: $background-color_1;
      color: $color_1;
    }
    &:focus {
      background-color: $background-color_1;
      color: $color_1;
    }
    &:hover {
      background-color: $background-color_1;
      color: $color_1;
    }
  }
  .dark-head-card {
    background: #2a2e31;
    border-radius: 10px;
  }
  .d-c-head {
    padding: 20px;
    border-bottom: 2px solid #212528;
  }
  .d-c-body {
    padding: 20px;
  }
  .cus-floating-inputs {
    .form-control {
      height: 46px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      background: transparent;
      font-family: $font-family_6;
      font-size: 14px;
      color: $color_1;
      font-size: 12px;
    }
    select.form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
  .cus-floating-inputs.form-floating {
    > label {
      padding: 0.6rem 0.75rem;
      color: $color_7;
      font-family: $font-family_6;
      font-size: 14px;
      padding: 0px;
      transform: scale(1) translateY(0.7rem) translateX(0.95rem);
      background-color: $background-color_7;
      height: auto;
      opacity: 1;
    }
    > .form-control {
      &:focus {
        ~ {
          label {
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            background-color: $background-color_7;
            opacity: 1;
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            opacity: 1;
          }
        }
      }
      &:not(:placeholder-shown) {
        ~ {
          label {
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            background-color: $background-color_7;
            opacity: 1;
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            opacity: 1;
          }
        }
      }
    }
    > .form-select {
      ~ {
        label {
          transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
          height: auto;
          background-color: $background-color_7;
          opacity: 1;
          transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
          height: auto;
          opacity: 1;
        }
      }
    }
  }
  .form-floating {
    > .form-control {
      &:focus {
        padding-top: 0.525rem;
        padding-bottom: 0.625rem;
      }
      &:not(:placeholder-shown) {
        padding-top: 0.525rem;
        padding-bottom: 0.625rem;
      }
    }
  }
  .form-select {
    &:focus {
      box-shadow: none;
    }
  }
  .dark-modal {
    .modal-content {
      background: #2a2e31;
    }
  }
  .s-ani-img {
    max-width: 100px;
    height: 100px;
    width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .suc-m-block {
    h4 {
      font-size: 18px;
      color: $color_1;
      letter-spacing: 0.05em;
      text-align: center;
    }
    p {
      font-size: 14px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .suc-m-body {
    padding: 50px 30px 30px;
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 15px;
    button {
      background-color: $background-color_1;
      border: 0px;
      padding: 0px;
    }
  }
  .modal-backdrop.show {
    background: linear-gradient(
      116.01deg,
      rgba(47, 50, 53, 0.66) 0.84%,
      rgba(33, 37, 40, 0.66) 16%,
      rgba(0, 0, 0, 0.66) 45.27%
    );
    backdrop-filter: blur(2px);
    opacity: 1;
  }
  .m-head {
    h3 {
      font-size: 18px;
      color: $color_1;
      letter-spacing: 0.05em;
      font-family: $font-family_2;
    }
  }
  .error-msg {
    color: $color_2;
  }
  .opacity04 {
    opacity: 0.4;
  }
  .modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px;
  }
  hr {
    &:not([size]).black-hr {
      height: 2px;
      background: #212528;
      opacity: 1;
    }
    &:not([size]).black-light-hr {
      height: 2px;
      background: #2c3033;
      opacity: 1;
    }
  }
  .cus-up-card {
    border: 1px dashed rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    min-height: 305px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    label {
      position: absolute;
      top: -9px;
      left: 20px;
      background: #2a2e31;
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .up-con {
    text-align: center;
    h5 {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .b-block {
    position: relative;
    color: $color_6;
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0;
      cursor: pointer;
      z-index: 9;
    }
  }
  .up-name-card {
    border: 1px dashed rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 15px;
    max-width: 250px;
    width: 100%;
    display: flex;
  }
  .f-text {
    flex: 1;
    padding-left: 15px;
    a {
      font-size: 12px;
      color: $color_6;
      font-family: $font-family_6;
      display: block;
    }
    small {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .mo-wrap {
    white-space: nowrap;
  }
  .mr-b8 {
    margin-bottom: 8px;
  }
  .label-title {
    font-size: 12px;
    color: $color_7;
    font-family: $font-family_6;
  }
  .satic-form-group {
    line-height: 0;
  }
  .cus-toggle-switch {
    label {
      margin: 0px;
      cursor: pointer;
      input {
        display: none;
        &:checked {
          ~ {
            span {
              background: #45a717;
              &::after {
                transform: translateY(-50%) translateX(12px);
                transition: all 0.3s linear 0s;
                border: 1px solid rgba(82, 156, 70, 1);
              }
            }
          }
        }
      }
      span {
        width: 28px;
        height: 16px;
        border-radius: 50px;
        background: rgba(255, 255, 255, 0.4);
        display: block;
        position: relative;
        &::after {
          content: "";
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #ffffff;
          left: 0px;
          top: 50%;
          transform: translateY(-50%) translateX(1px);
          transition: all 0.3s linear 0s;
          position: absolute;
          border: 1px solid #ffffff;
        }
      }
    }
  }
  .nav-tabs {
    .nav-link {
      font-size: 18px;
      letter-spacing: 0.05em;
      font-family: $font-family_2;
      position: relative;
      display: block;
      overflow: hidden;
      padding: 10px 0px;
      margin-right: 30px;
      background-color: $background-color_1;
      border: 0px;
      color: $color_7;
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background: #0177fb;
        position: absolute;
        left: 0px;
        bottom: 0px;
        transform: translateY(2px);
        transition: all 0.3s linear 0s;
      }
    }
    .nav-item {
      &:last-child {
        .nav-link {
          margin-right: 0px;
        }
      }
    }
    .nav-item.show {
      .nav-link {
        background-color: $background-color_1;
        border: 0px;
        color: $color_7;
        color: $color_1;
        &::after {
          transform: translateY(-1px);
          transition: all 0.3s linear 0s;
        }
      }
    }
    .nav-link.active {
      background-color: $background-color_1;
      border: 0px;
      color: $color_7;
      color: $color_1;
      &::after {
        transform: translateY(-1px);
        transition: all 0.3s linear 0s;
      }
    }
    border-bottom: 0px;
  }
  .domains-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }
  .domains-c-head {
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    h5 {
      font-size: 12px;
      color: $color_1;
      font-family: $font-family_6;
    }
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }
  .domains-card-body {
    padding: 12px;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
    h4 {
      font-size: 20px;
      color: $color_1;
      font-family: $font-family_2;
      span {
        font-size: 12px;
        color: $color_7;
        font-family: $font-family_6;
        margin-left: 12px;
      }
    }
  }
  .dom-drop-box {
    min-width: 134px;
    background: #2a2e31;
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 0px;
    li {
      .dropdown-item {
        .da-icon {
          display: flex;
          align-items: center;
          margin-right: 10px;
        }
        font-size: 12px;
        color: $color_1;
        font-family: $font-family_2;
        display: flex;
        align-items: center;
        padding: 15px 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
      padding: 0px 15px;
      &:last-child {
        .dropdown-item {
          border-bottom: 0px;
        }
      }
    }
  }
  .ed-map-block {
    iframe {
      width: 100%;
      height: 234px;
      border-radius: 10px;
    }
  }
  .cus-pro-bar {
    .progress {
      background: #35383b;
      border-radius: 30px;
      height: 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
    .progress-bar {
      background: #45a717;
      border-radius: 50px;
      height: 4px;
    }
    .pro-value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 10px;
        color: $color_1;
        font-family: $font-family_2;
      }
    }
  }
  .a-prog-ani {
    .progress {
      height: 12px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 50px;
    }
    .progress-bar {
      background: rgb(42, 46, 49);
      background: -moz-linear-gradient(
        left,
        rgba(42, 46, 49, 1) 0%,
        rgba(1, 119, 251, 1) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(42, 46, 49, 1) 0%,
        rgba(1, 119, 251, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(42, 46, 49, 1) 0%,
        rgba(1, 119, 251, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2a2e31', endColorstr='#0177fb', GradientType=1);
      border-radius: 50px;
    }
  }
  .cus-r-btn {
    font-size: 12px;
    color: $color_1;
    font-family: $font-family_2;
    label {
      display: flex;
      align-items: center;
    }
    input {
      display: none;
      &:checked {
        ~ {
          span {
            border: 2px solid #0177fb;
            &::after {
              content: "";
              width: 8px;
              height: 8px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background: #0177fb;
              border-radius: 50%;
            }
          }
        }
      }
    }
    span {
      display: block;
      width: 16px;
      height: 16px;
      border: 2px solid #8c8c8c;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;
    }
  }
  .cus-c-btn {
    font-size: 12px;
    color: $color_1;
    font-family: $font-family_2;
    label {
      display: flex;
      align-items: center;
    }
    input {
      display: none;
      &:checked {
        ~ {
          span {
            border: 2px solid #0177fb;
            background-color: $background-color_2;
            &::after {
              content: "L";
              position: absolute;
              transform: scalex(-1) rotate(-397deg);
              transform-origin: center;
              font-size: 12px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    span {
      display: block;
      width: 16px;
      height: 16px;
      border: 1px solid #8c8c8c;
      position: relative;
      margin-right: 10px;
      border-radius: 2px;
    }
  }
  .ds-board {
    height: calc(100vh - 9.8em);
    display: flex;
    flex-wrap: wrap;
  }
  .ds-search-col {
    max-width: 284px;
    width: 100%;
    position: relative;
  }
  .ds-resuld-col {
    flex: 1;
    border-radius: 4px;
    position: relative;
  }
  .ds-serach-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 10px;
    input {
      background: transparent;
      border: 0px;
      font-size: 12px;
      font-family: $font-family_6;
      box-sizing: border-box;
      color: $color_1;
      flex: 1;
      padding-right: 61px;
      width: 100%;
      &::placeholder {
        color: $color_7;
      }
      &:-ms-input-placeholder {
        color: $color_7;
      }
      &::-ms-input-placeholder {
        color: $color_7;
      }
    }
    button {
      background-color: $background-color_1;
      border: 0px;
      padding: 0px 15px;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      line-height: 0;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  .ds-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .list-block {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.4);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $background-color_7;
      border: 1px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }
    ul {
      padding: 0px;
      margin: 0px;
      li {
        list-style-type: none;
        font-size: 12px;
        color: $color_1;
        font-family: $font-family_6;
        padding: 8px 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        &:hover {
          background: rgba(255, 255, 255, 0.05);
          cursor: pointer;
        }
      }
      li.list-active {
        background: rgba(255, 255, 255, 0.05);
        cursor: pointer;
      }
    }
  }
  .ds-r-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ds-r-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .b-card-row {
    flex: 1;
    overflow: auto;
  }
  .ds-r-head {
    h3 {
      font-size: 16px;
      color: $color_1;
      font-family: $font-family_2;
      margin-bottom: 15px;
    }
    .nav-tabs {
      .nav-link {
        font-size: 14px;
      }
    }
  }
  .info-black-card {
    background: #212528;
    padding: 20px;
  }
  .info-gray-card {
    background: #2c3033;
    padding: 20px;
  }
  .mr-b15 {
    margin-bottom: 15px;
  }
  .over-wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }
  .cus-scroll {
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.4);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $background-color_7;
      border: 1px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }
  }
  .ds-foot {
    padding: 30px 0px 0px;
  }
  .m-d-list {
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        margin-bottom: 10px;
      }
    }
  }
  .tls-msg-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
  }
  .policies-block {
    background-color: $background-color_6;
    padding: 10px;
    border-radius: 5px;
    .tls-msg-card {
      background-color: $background-color_1;
    }
  }
  .n-list-wrap {
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        padding: 12px 20px;
        font-family: $font-family_2;
        font-size: 12px;
        color: $color_1;
        margin-bottom: 10px;
      }
    }
  }
  .gray-box {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
  }
  .black-card {
    background: #212528;
  }
  .val-pro-box {
    max-width: 191px;
    width: 100%;
    .red-bar {
      background: #dc3545;
    }
  }
  .price-card {
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }
  .price-c-head {
    padding: 25px;
  }
  .price-card-body {
    padding: 25px;
  }
  .price-list {
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        font-size: 12px;
        color: $color_1;
        font-family: $font-family_6;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .green-tick {
    &::before {
      content: "";
      width: 100%;
      max-width: 24px;
      flex: 0 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .cross-tick {
    &::before {
      content: "";
      width: 100%;
      max-width: 24px;
      flex: 0 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("../images/cross.png") no-repeat center;
    }
  }
  .blue-c-head {
    background: #0177fb;
    border: 1px solid #0177fb;
    border-radius: 4px;
    position: relative;
  }
  .batch-note {
    background: #d8eaff;
    border-radius: 2px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    min-width: 112px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: 30px;
  }
  .pay-m-block {
    background: #2c3033;

    ul {
      li {
        border: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }

  .t-strip {
    background: #d8eaff;
    padding: 10px;
    margin: 10px -10px;
  }
  .order-block {
    margin-top: 15px;
  }
  .breack-list {
    ul {
      padding: 0px;
      margin: 0px;
      max-width: 263px;
      width: 100%;
      li {
        background: #212528;
        padding: 10px;
        list-style-type: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        h5 {
          font-size: 12px;
          color: $color_1;
          font-family: $font-family_6;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &:last-child {
          background: #d8eaff;
          h5 {
            font-family: $font-family_2;
            color: $color_6;
          }
        }
      }
    }
  }
  .chart-block {
    img {
      width: 100%;
      height: auto;
    }
  }
  .h-80 {
    max-height: 400px;
    overflow-x: auto;
  }
  .ant-table-wrapper {
    background-color: $background-color_11;
  }
  .ant-table {
    background-color: $background-color_12;
    background-color: $background-color_1 !important;
    th {
      color: $color_11 !important;
      font-size: 12px;
      font-family: $font-family_6;
      font-weight: 200 !important;
    }
    td {
      border-bottom: 1px solid var(--white-20, rgba(255, 255, 255, 0.2)) !important;
      color: $color_12 !important;
      font-size: 12px;
      font-family: $font-family_6 !important;
      padding: 1px;
    }
  }
  .ant-table-cell {
    padding: 0px 4px !important;
    background: #212529 !important;
    border: none !important;
    &::before {
      display: none !important;
    }
  }
  .ant-table-container {
    border-radius: 0px !important;
  }
  .ant-table-thead {
    &::before {
      background-color: $background-color_12 !important;
    }
    > tr {
      > th {
        padding: 10px 4px !important;
      }
    }
  }
  &.ant-table-wrapper {
    .ant-table-container {
      table {
        > thead {
          > tr {
            &:first-child {
              > * {
                &:first-child {
                  border-start-start-radius: 0px !important;
                }
                &:last-child {
                  border-start-end-radius: 0px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .inherit-nest {
    .ant-table-cell {
      background-color: $background-color_7 !important;
      padding: 0px 4px !important;
    }
    .ant-table-container {
      background-color: $background-color_7 !important;
    }
    .ant-table-wrapper {
      background-color: $background-color_7 !important;
    }
  }
  .ant-select-arrow {
    color: $color_13 !important;
  }
  .page-header {
    font-family: $font-family_8;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: $color_1;
  }
  .add-btn {
    background: #0177fb;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .search {
    width: 200px;
  }
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: -1px 5px;
  }
  .bg-green {
    background-color: $background-color_13;
  }
  .bg-red {
    background-color: $background-color_14;
  }
  .swal2-popup {
    background: #2a2e31 !important;
    color: $color_1 !important;
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  .icon {
    color: $color_1;
    font-size: 20px;
  }
  .ant-pagination-item {
    color: $color_1;
  }
  .ant-pagination-item-link {
    color: $color_1 !important;
  }
  .ant-pagination-item-ellipsis {
    color: $color_1 !important;
  }
  .ant-pagination {
    .ant-pagination-item-active {
      background-color: $background-color_11;
      border-color: $border-color_1;
    }
  }
  .ant-select-selector {
    background-color: $background-color_11 !important;
    border-color: $border-color_1 !important;
    color: $color_1 !important;
    border: 1px solid #d4b8b8af !important;
    border-color: $border-color_2 !important;
    border-radius: 4px !important;
    height: 43px !important;
    padding: 6px 8px !important;
    overflow: hidden !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .input-affix-wrapper {
    background-color: $background-color_7 !important;
    input {
      background-color: $background-color_7 !important;
      color: $color_1 !important;
    }
  }
  .dark-card {
    width: 100%;
    height: 100%;
    background: #2a2e31;
    padding: 10px;
  }
  .bulk-btn {
    width: 105px;
    height: 32px;
    background: #d8eaff;
    border-radius: 4px;
    font-family: $font-family_8;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $color_6;
    padding: 6px 10px;
  }
  .ant-empty-description {
    color: $color_1 !important;
  }
  .cursor {
    cursor: pointer;
  }
  .MuiOutlinedInput-root {
    color: $color_1 !important;
  }
  .ant-table-tbody {
    > tr.ant-table-row {
      &:hover {
        > td {
          background-color: $background-color_15 !important;
        }
      }
    }
    > tr {
      > td.ant-table-cell-row-hover {
        background-color: $background-color_15 !important;
      }
    }
  }
  .ant-select {
    width: 100%;
  }
  .ant-select-dropdown {
    border: 1px solid #bdbdbd !important;
    border-top: none !important;
    border-radius: 0 0 4px 4px !important;
    margin-top: 0 !important;
    box-shadow: none !important;
    background: #2a2e31;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 4px;
  }
  .ant-select-dropdown-menu-item {
    color: $color_14 !important;
  }
  .ant-select-selection-item-content {
    color: $color_15;
    font-size: 10px;
    color: $color_1;
    font-family: $font-family_2;
    background: rgba(255, 255, 255, 0) !important;
  }
  .anticon-close {
    svg {
      fill: #fff !important;
      color: $color_15;
    }
  }
  .ant-select-selection-placeholder {
    color: $color_7;
    font-size: 14px;
    font-family: $font-family_6;
    color: $color_7 !important;
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: #ffffff;
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 12px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: $border-color_2 !important;
    border: 1px solid;
  }
  label[data-shrink="false"] {
    + {
      .MuiInputBase-formControl {
        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
          height: 10px !important;
        }
      }
    }
  }
  .error {
    span {
      color: $color_16;
      font-family: $font-family_9;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      text-align: left;
      margin-top: 3px;
      margin-right: 14px;
      margin-bottom: 0;
      margin-left: 14px;
    }
  }
  &:where(.css-dev-only-do-not-override-diro6f).ant-menu-light {
    font-size: 14px;
    color: $color_9;
    font-family: $font-family_6;
    display: flex;
    align-items: center;
    background: #2a2e31 !important;
  }
  .grey-border {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .text-grey {
    span {
      font-size: 12px;
      font-family: $font-family_6;
      color: $color_1 !important;
      padding: 5px 10px;
    }
  }
  .bg-black {
    background-color: $background-color_6;
    padding: 10px;
  }
  .bg-grey {
    background: #35383b !important;
  }
  td {
    color: $color_15;
  }
  th {
    color: $color_15;
  }
  &:where(.css-dev-only-do-not-override-diro6f).ant-select-multiple {
    .ant-select-selection-placeholder {
      font-size: 14px;
      font-family: $font-family_6;
      color: $color_7 !important;
    }
    .ant-select-selection-item {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50px;
      padding: 0px 8px;
      border: 0px;
    }
    .ant-select-selection-item-remove {
      line-height: 17px;
    }
  }
  &:where(.css-dev-only-do-not-override-diro6f).ant-select-dropdown {
    .ant-select-item {
      font-size: 12px;
      font-family: $font-family_2;
      color: $color_1;
      margin: 2px 0px;
    }
    .ant-select-item-option-selected {
      &:not(.ant-select-item-option-disabled) {
        font-weight: normal;
      }
    }
  }
  .ant-select-selection-item {
    background: rgba(255, 255, 255, 0.2) !important;
  }
  .ant-select-item-option-selected {
    background: rgba(255, 255, 255, 0.2) !important;
  }
  .ant-menu {
    .ant-menu-root {
      background: "#2a2e31" !important;
    }
  }
  .active {
    color: $color_6 !important;
  }
  .submit-Button {
    background-color: $background-color_2 !important;
  }
  .custom-cancel-button {
    background-color: $background-color_3 !important;
    color: $color_6 !important;
  }
  .switch-label {
    font-family: $font-family_6;
    font-weight: 400;
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
    color: $color_7;
  }
  .span-title {
    font-family: $font-family_6;
    font-weight: 700;
    font-size: 14px;
    color: $color_1;
  }
  .dark-mode-editor {
    .jodit_container {
      background-color: $background-color_16;
      color: $color_15;
    }
    .jodit_toolbar {
      .jodit_toolbar_btn {
        color: $color_15;
      }
    }
  }
  .jodit_theme_dark {
    .jodit-toolbar__box {
      &:not(:empty) {
        background: #2a2e31;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
      }
    }
    .jodit-wysiwyg {
      background: #2a2e31;
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
    }
    .jodit-workplace {
      + {
        .jodit-status-bar {
          &:not(:empty) {
            background-color: $background-color_7;
            border-color: $border-color_3;
            color: $color_17;
            border: 1px solid rgba(255, 255, 255, 0.1) !important;
          }
        }
      }
    }
  }
  .notification {
    background: #2a2e31;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 20px;
    margin-top: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: $color_1;
    }
  }
  .text-link {
    font-family: $font-family_6;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    cursor: pointer;
    color: $color_6;
  }
  .heading-label {
    font-family: $font-family_6;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color_7;
  }
  .heading-value {
    font-family: $font-family_6;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: $color_1;
  }
  .outline-effect {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 2px;
  }
  .outline-label {
    background-color: $background-color_11;
    color: #ffffff !important;
  }
  .d-c-head-black {
    background: #2a2e31;
    margin: 10px;
    padding: 10px;
  }
  .heading {
    color: $color_12;
    font-size: 12px;
    font-family: $font-family_6;
    line-height: 18px;
    margin-bottom: 20px;
  }
  .nest-table {
    .ant-table-cell {
      background-color: $background-color_7;
      padding: 0px 4px !important;
    }
    th {
      color: $color_18 !important;
      font-size: 12px;
      font-family: $font-family_6;
      font-weight: 400 !important;
      padding-left: 10px;
    }
    &.ant-table-wrapper {
      .ant-table-container {
        table {
          > thead {
            > tr {
              &:first-child {
                > * {
                  &:first-child {
                    border-start-start-radius: 0px;
                  }
                  &:last-child {
                    border-start-end-radius: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
    td {
      color: $color_1 !important;
      font-size: 12px;
      font-family: $font-family_6;
      border-bottom: 1px solid #ffffff66 !important;
    }
  }
  .tab-label {
    color: $color_7;
    font-size: 14px;
    font-family: $font-family_6;
    font-weight: 700;
    letter-spacing: 0.7px;
    padding: 10px;
    cursor: pointer;
  }
  .tab-active {
    color: $color_15;
    border-bottom: 2px solid #0177fb;
    cursor: pointer;
  }
  .note {
    color: $color_11;
    font-size: 12px;
    font-family: $font-family_6;
    font-weight: 500;
  }
  .fw-800 {
    font-weight: 800;
  }
  .ant-modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    padding-top: 10px;
  }
  .swal2-html-container {
    color: $color_7;
    text-align: center;
    font-size: 14px;
    font-family: $font-family_6;
    letter-spacing: 0.7px;
  }
  .swal2-title {
    color: $color_15;
    font-size: 18px;
    font-family: $font-family_6;
    font-weight: 700;
    letter-spacing: 0.9px;
  }
  .swal2-confirm {
    background: #0177fb !important;
    color: $color_15;
    text-align: center;
    font-size: 14px;
    font-family: $font-family_6;
    font-weight: 500;
  }
  .high {
    color: $color_4;
  }
  .label-white {
    color: $color_12;
    font-size: 12px !important;
    font-family: $font-family_6;
    display: block;
  }
  .label-gray {
    color: $color_11;
    font-size: 12px;
    font-family: $font-family_6;
    display: block;
  }
  .f-700 {
    font-weight: 700;
  }
  .c-green {
    color: $color_19;
  }
  .span-gray {
    span {
      color: $color_11;
      font-size: 12px;
      font-family: $font-family_6;
    }
  }
  .b-black {
    background-color: $background-color_6;
  }
  .dropdown-menu {
    background: #2a2e31;
  }
  .border-bottom-1 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .dropdown-divider {
    margin: 5px;
  }
  .feature {
    background-color: $background-color_6;
    border-bottom: 1px solid;
  }
  .c-sky {
    background-color: $background-color_3;
  }
  .text-blue {
    color: $color_6;
    font-size: 12px;
    font-family: $font-family_6;
    font-style: normal;
    font-weight: 700;
  }
  .border-bottom-gray {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .plan-card {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
    padding: 20px;
  }
  .fs-18 {
    font-size: 18px;
    font-family: $font-family_6;
  }
  .fs-32 {
    font-size: 32px;
    font-family: $font-family_6;
  }
  .fs-12 {
    font-size: 12px;
    font-family: $font-family_6;
  }
  .f-gray {
    color: $color_11;
    font-size: 12px;
    font-family: $font-family_6;
  }
  .selected_device {
    background: #2c3033;
  }
  @media (max-width: 768px) {
    .bottom-button {
      left: 0%;
    }
    .login-wrap {
      padding: 10px;
    }
    .l-inn-content {
      padding: 20px;
    }
    .login-box {
      &::after {
        display: none;
      }
      h3 {
        font-size: 18px;
      }
      h5 {
        font-size: 13px;
      }
    }
    .l-btm-btn {
      text-align: center;
      button {
        height: 40px;
        font-size: 11px;
        padding: 0px 20px;
      }
    }
    .f-r-block {
      a {
        font-size: 12px;
      }
      .r-cus-check {
        font-size: 12px;
      }
    }
    .l-s-text {
      font-size: 12px;
    }
    .l-b-text {
      font-size: 10px;
    }
  }
  .dashboard-graph {
    background-color: $background-color_7;
  }
  .dashboard-graph1 {
    background-color: $background-color_7;
  }

  .graph-title {
    color: var(--white-100, #fff);
  }

  .apexcharts-legend-text {
    color: #fff !important;
  }

  .inherit-nest .ant-table-cell {
    background-color: #2a2e31 !important;
    padding: 0px 4px !important;
  }

  .device-table .ant-table-cell {
    background-color: #2a2e31 !important;
    padding: 10px 4px !important;
  }

  .inherit-nest .ant-table-container {
    background-color: #2a2e31 !important;
  }

  .ant-table-column-sorter-inner {
    color: rgb(110, 113, 124);
    // margin-right: 10px;
  }

  .ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
    background-color: #9697a1;
  }
  .addEditTestLabel {
    color: rgba(255, 255, 255, 0.4);
  }
  .addEditTestDesc {
    color: #ffffff;
  }
  .colorLabel {
    color: rgba(255, 255, 255, 0.4);
  }
  .threat-hunting-legend {
    color: rgba(255, 255, 255, 0.4);
    // font-family: Inter-Regular;
    // font-size: 14px;
    // font-weight: 100;
  }
  .threat-hunting-fieldset {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .inherit-nest .ant-spin-container {
    background-color: #2a2e31 !important;
  }
  .test_details_inner_collapse {
    background-color: #212528;
  }

  .console_detail {
    color: #ffffff;
  }
  @media (min-width: 576px) {
    .modal-dialog.dialog450 {
      max-width: 450px;
      margin: 1.75rem auto;
    }
  }
}

.white-theme {
  $color_1: var(--darkBlack);
  $color_2: #dc3545;
  $color_3: #01b8aa;
  $color_4: #fe7750;
  $color_5: #f0c555;
  $color_6: #0177fb;
  $color_7: var(--grey);
  $color_8: #212121;
  $color_9: #a5b3cd;
  $color_10: rgba(255, 255, 255, 1);
  $color_11: var(--darkBlack-100);
  $color_12: var(--green);
  $color_13: #333;
  $color_14: #fff;
  $color_15: rgba(255, 255, 255, 0.4);
  $color_16: #d32f2f;
  $color_17: #d1cccc;
  $color_18: var(--white-100, #fff);
  $color_19: var(--darkBlack) 66;
  $color_20: var(--white-40, rgba(255, 255, 255, 0.4));
  $font-family_1: Inter-Black;
  $font-family_2: Inter-Bold;
  $font-family_3: Inter-ExtraBold;
  $font-family_4: Inter-Light;
  $font-family_5: Inter-Medium;
  $font-family_6: Inter-Regular;
  $font-family_7: Inter-Thin;
  $font-family_8: "Inter";
  $font-family_9: "Roboto", "Helvetica", "Arial", sans-serif;
  $background-color_1: transparent;
  $background-color_2: #0177fb;
  $background-color_3: #d8eaff;
  $background-color_4: #dc3545;
  $background-color_5: #212121;
  $background-color_6: var(--pureWhite);
  $background-color_7: var(--whiteShade-100);
  $background-color_8: #01b8aa;
  $background-color_9: #fe7750;
  $background-color_10: rgba(255, 255, 255, 0.05);
  $background-color_11: var(--darkBlue-100);
  $background-color_12: var(--green);
  $background-color_13: red;
  $background-color_14: inherit;
  $background-color_15: #2a2e31;
  $background-color_16: #ffffff;
  $background-color_17: gray;
  $background-color_18: #333;
  $background-color_19: #212528;
  $border-color_1: #1677ff;
  $border-color_2: rgba(255, 255, 255, 0.1);
  $border-color_3: #6b6b6b;
  $gray-100: #f1f1f1;
  $gray-200: #e4e4e4;
  $blue-100: #2a3f54;
  .outline-label-1 {
    background: #e4e4e4;
  }
  .card-text-white h5 {
    color: $background-color_19;
  }
  .configure_header span {
    color: $color_13;
  }
  .reccurence-modal-body {
    background-color: $background-color_3;
  }

  .footer {
    background: $blue-100;
  }

  .ruleset-add-btn,
  .container-add {
    color: #808080 !important;
  }
  .ruleset-second-row {
    border: 1px solid #afafaf;
    border-radius: 2%;
  }
  .subline-dropdown {
    color: #1f1f1f66;
  }

  /* all margin */
  /* margin top */
  /* margin right */
  /* margin bottom */
  /* margin left */
  /* padding  */
  /* padding top */
  /* padding right */
  /* padding bottom */
  /* padding left */
  /* Login css  */
  /* login media css  */
  /* Sidebar css */
  /* Track */
  /* Handle */
  /* Track */
  /* Handle */
  /* New CSS  */
  /* .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.css-mli2yg-MuiFormControl-root-MuiTextField-root .MuiInputLabel-root,
.MuiFormLabel-root {
  font-family: Inter-Regular;
  font-size: 12px;
  color: var(--grey);
}

.css-14lo706 > span {
  color: var(--grey);
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  font-size: 12px;
  color: var(--grey);
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(18px, -7px) scale(0.85);
  -webkit-transform: translate(18px, -7px) scale(0.85);
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  font-size: 12px;
  color:  var(--darkBlack) !important;
  font-family: Inter-Bold;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(15px, 13px) scale(1);
}

.css-mli2yg-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
  font-family: Inter-Regular;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color:  var(--darkBlack) !important;
  font-size: 12px;
  font-family: Inter-Bold;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 11px 14px !important;
}

.css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.1);
} */
  /* .MuiSelect-icon path {
  display: none;
}

.MuiSelect-icon {
  background: url("../images/w-d-icon.png") no-repeat center;
} */

  /* .ant-table {
    background-color: #2a2e31;
  } */
  /* .ant-table-thead th {
  color:  var(--darkBlack) !important;
  background-color: #2a2e31 !important;
  border: none !important;
} */
  /* input {
    color:  var(--darkBlack) !important;
    height: 12px !important;
  } */
  /* .MuiFormLabel-root {
  padding: 0.6rem 0.75rem;
  color: rgba(255, 255, 255, 0.4);
  font-family: Inter-Regular;
  font-size: 14px;
  padding: 0px;
  background-color: #2a2e31;
  height: auto;
  opacity: 1;
} */
  /* Define styles for the Select component */
  /* Define styles for the input element */
  /* .ant-select-selector input {
    border: none !important;
    outline: none !important;
  } */
  /* Define styles for the control wrapper */
  /* Define styles for the focused state of the control wrapper */
  /* .ant-select-focused .ant-select-selector {
    border-color: #3f51b5 !important;
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2) !important;
  } */
  /* Define styles for the selected option */
  /* .ant-select-selection-item {
    color: #3f51b5 !important;
  } */
  /* Define styles for the menu */
  /* Define styles for the menu options */
  /* Define styles for the selected menu option */
  /* .ant-select-item-option-selected {
    background-color: #3f51b5 !important;
    color: #fff !important;
  } */
  /* .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid;

} */
  /* .ant-select-selection-item {
  background: rgba(255, 255, 255, 0.2) !important;
} */
  /* Add more custom styles as needed */
  /* .bg-blue {
  background-color: #0177fb;
} */

  body {
    background: $gray-100;
  }

  .device-heading {
    color: black;
  }
  .btn-secondary {
    &:hover {
      background-color: $background-color_1;
    }
    &:focus {
      background-color: $background-color_1;
    }
  }
  a {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    text-decoration: none;
    opacity: 1;
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
  }
  button {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    text-decoration: none;
    opacity: 1;
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
  }
  input {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    text-decoration: none;
    opacity: 1;
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
  }
  h1 {
    margin: 0px;
    padding: 0px;
  }
  h2 {
    margin: 0px;
    padding: 0px;
  }
  h3 {
    margin: 0px;
    padding: 0px;
  }
  h4 {
    margin: 0px;
    padding: 0px;
  }
  h5 {
    margin: 0px;
    padding: 0px;
  }
  h6 {
    margin: 0px;
    padding: 0px;
  }
  p {
    margin: 0px;
    padding: 0px;
  }
  .mr0 {
    margin: 0px;
  }
  .mr10 {
    margin: 10px;
  }
  .mr20 {
    margin: 20px;
  }
  .mr30 {
    margin: 30px;
  }
  .mr40 {
    margin: 40px;
  }
  .mr50 {
    margin: 50px;
  }
  .mr60 {
    margin: 60px;
  }
  .mr70 {
    margin: 70px;
  }
  .mr80 {
    margin: 80px;
  }
  .mr90 {
    margin: 90px;
  }
  .mr100 {
    margin: 100px;
  }

  .mr-r10 {
    margin-right: 10px;
  }
  .mr-r20 {
    margin-right: 20px;
  }
  .mr-r30 {
    margin-right: 30px;
  }
  .mr-r40 {
    margin-right: 40px;
  }
  .mr-r50 {
    margin-right: 50px;
  }
  .mr-r60 {
    margin-right: 60px;
  }
  .mr-r70 {
    margin-right: 70px;
  }
  .mr-r80 {
    margin-right: 80px;
  }
  .mr-r90 {
    margin-right: 90px;
  }
  .mr-r100 {
    margin-right: 100px;
  }
  .mr-b0 {
    margin-bottom: 0px;
  }

  .Inter-Black {
    font-family: $font-family_1;
  }
  .Inter-Bold {
    font-family: $font-family_2;
  }
  .Inter-ExtraBold {
    font-family: $font-family_3;
  }
  .Inter-Light {
    font-family: $font-family_4;
  }
  .Inter-Medium {
    font-family: $font-family_5;
  }
  .Inter-Regular {
    font-family: $font-family_6;
  }
  .Inter-Thin {
    font-family: $font-family_7;
  }

  .lh17 {
    line-height: 17px;
  }
  .h46 {
    height: 46px;
  }

  .color-white {
    color: $color_1;
  }
  .color-red {
    color: $color_2;
  }
  .color-green {
    color: $color_3;
  }
  .color-orange {
    color: $color_4;
  }
  .color-yellow {
    color: $color_5;
  }
  .color-blue {
    color: $color_6;
  }
  .blue-btn {
    background-color: $blue-100;
    border: 1px solid $blue-100;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    &:hover {
      color: $blue-100;
      background-color: $background-color_1;
    }
  }
  .gray-bor-btn {
    background-color: $background-color_1;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    text-align: center;
    color: $color_7;
  }

  .red-btn {
    background-color: $background-color_4;
    border: 1px solid #dc3545;
    border-radius: 4px;
    color: $color_1;
    text-align: center;
  }
  .black-trans-btn {
    background-color: $background-color_1;
    border: 1px solid #212121;
    border-radius: 50px;
    font-size: 16px;
    color: $color_8;
    text-align: center;
    &:hover {
      background-color: $background-color_5;
      color: $color_1;
    }
  }
  .trans-btn {
    background: transparent;
    border: 0px;
    padding: 0px;
    line-height: 0px;
  }
  .file-icon {
    line-height: 0px;
  }
  .c-icon {
    line-height: 0px;
  }
  .login-wrap {
    background-color: $background-color_6;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  .lt-logo-block {
    img {
      max-width: 105px;
      width: 100%;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }
  .login-box {
    max-width: 630px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 27em);
      height: 100vh;
      background: linear-gradient(
        269.36deg,
        rgba(42, 46, 49, 0.4) 4.22%,
        rgba(42, 46, 49, 0) 109.7%
      );
      right: 0px;
      top: 0px;
      transform: rotate(-13.5deg) translateX(-7px) translateY(0px) skewX(44deg)
        skewY(-18.4deg);
      -webkit-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      -moz-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      -ms-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      -o-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      z-index: 1;
      transform-origin: top right;
      -webkit-transform-origin: top right;
      -moz-transform-origin: top right;
      -ms-transform-origin: top right;
      -o-transform-origin: top right;
      pointer-events: none;
    }
    h5 {
      font-size: 16px;
      color: $color_7;
      font-family: $font-family_6;
      text-align: center;
    }
    h3 {
      font-size: 24px;
      color: $color_1;
      font-family: $font-family_2;
      text-align: center;
    }
  }
  .l-inn-content {
    width: 100%;
    background: var(--whiteShade-100);
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 70px 60px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  .l-form {
    max-width: 300px;
    width: 100%;
    margin: 40px auto 0px;
  }
  .cus-f-group {
    label.f-input-title {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
      position: absolute;
    }
    .cus-from-control {
      height: 46px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      background-color: $background-color_1;
      font-size: 12px;
      font-family: $font-family_6;
      color: $color_1;
    }
  }
  .floting-input {
    position: relative;
    label {
      position: absolute;
      top: 0px;
      left: 10px;
      padding: 0px 10px;
      background-color: $background-color_7;
      top: -9px;
    }
  }
  .f-r-block {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .r-cus-check {
    font-size: 12px;
    color: $color_7;
    font-family: $font-family_6;
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
      display: none;
      &:checked {
        ~ {
          span {
            background: #0177fb;
            border: 1px solid #0177fb;
            &::after {
              content: "L";
              position: absolute;
              width: 100%;
              height: 100%;
              font-size: 11px;
              font-family: $font-family_5;
              color: $color_1;
              transform: scaleX(-1) rotate(-48deg);
              display: inline-flex;
              align-items: center;
              top: -1px;
              justify-content: center;
              left: 0px;
            }
          }
        }
      }
    }
    span {
      width: 16px;
      height: 16px;
      display: block;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 2px;
      position: relative;
    }
  }
  .in-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 17px;
    background-color: $background-color_1;
    border: 0px;
    padding: 0px;
    line-height: 0px;
  }
  .top-bg-line {
    height: 4px;
    width: 38vw;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(37deg) translateX(12vw);
    transform-origin: center;
    background: linear-gradient(
      289.53deg,
      rgba(1, 119, 251, 0.3) 2.87%,
      rgba(1, 119, 251, 0) 99.44%
    );
    pointer-events: none;
  }
  .top-gg-line {
    height: 4px;
    width: 53vw;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(37deg) translateX(12vw);
    transform-origin: center;
    background: linear-gradient(
      289.6deg,
      var(--whiteShade-100) 5.38%,
      rgba(42, 46, 49, 0) 99.45%
    );
    pointer-events: none;
  }
  .bottom-bg-line {
    height: 4px;
    width: 52vw;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(37deg) translateX(-14vw);
    transform-origin: center;
    background: -moz-linear-gradient(
      left,
      rgba(1, 119, 251, 0) 0%,
      rgba(1, 119, 251, 0) 19%,
      rgba(1, 119, 251, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(1, 119, 251, 0) 0%,
      rgba(1, 119, 251, 0) 19%,
      rgba(1, 119, 251, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(1, 119, 251, 0) 0%,
      rgba(1, 119, 251, 0) 19%,
      rgba(1, 119, 251, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000177fb', endColorstr='#0177fb', GradientType=1);
    pointer-events: none;
  }
  .bottom-gg-line {
    height: 4px;
    width: 38vw;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(37deg) translateX(-14vw);
    transform-origin: center;
    background: linear-gradient(
      289.6deg,
      var(--whiteShade-100) 5.38%,
      rgba(42, 46, 49, 0) 99.45%
    );
    pointer-events: none;
  }
  .l-s-text {
    font-size: 14px;
    color: $color_7;
    line-height: 17px;
    letter-spacing: 0.05em;
  }
  .l-b-text {
    font-size: 12px;
    color: $color_7;
    line-height: 15px;
    letter-spacing: 0.05em;
  }
  .sidebar-wrap {
    max-width: 223px;
    width: 100%;
    background: #2a3f54;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 999;
  }
  .s-top-logo {
    max-width: 72px;
    height: auto;
    width: 100%;
    margin: 30px auto;
    img {
      width: 100%;
    }
  }
  .s-nav {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    &::-webkit-scrollbar {
      width: 0px;
    }
    h5 {
      font-size: 12px;
      color: $color_9;
      font-family: $font-family_6;
      text-transform: uppercase;
    }
  }
  .s-bm-logo {
    padding: 20px;
  }
  .nav-links-wrap {
    .dropdown-menu {
      position: static !important;
      transform: translate(0) !important;
      transition: all 0.5s linear 0s;
    }
    .dropdown-menu.show {
      transition: all 0.5s linear 0s;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        margin: 24px 0px;
        a {
          font-size: 14px;
          color: $color_9;
          font-family: $font-family_6;
          display: flex;
          align-items: center;
          .snav-icon {
            width: 20px;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
          }
        }
        &:hover {
          a {
            color: $color_6;
            stroke: #0177fb;
            svg {
              path {
                color: $color_6;
                stroke: #0177fb;
              }
            }
          }
        }
        &:first-child {
          &:hover {
            a {
              svg {
                path {
                  fill: #0177fb;
                }
              }
            }
          }
        }
      }
    }
  }
  .top-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 24px 20px;
    padding-left: 225px;
    background-color: $gray-100;
    z-index: 999;
  }
  .head-pro-block {
    display: flex;
    align-items: center;
  }
  .pro-drop-box {
    background-color: $background-color_1;
    border: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
  }
  .user-pro-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 30px;
      max-height: 30px;
      object-fit: cover;
    }
  }
  .user-pro-info {
    line-height: 7px;
    text-align: left;
    padding-left: 10px;
    h4 {
      font-size: 12px;
      color: $color_1;
      font-family: $font-family_2;
    }
    a {
      font-size: 10px;
      color: $color_1;
      font-family: $font-family_6;
    }
  }
  .dropdown-toggle {
    &::after {
      margin-left: 14px;
    }
  }
  .ntification-block {
    margin-right: 25px;
  }
  .body-wrap {
    padding-left: 225px;
    padding-top: 80px;
    background-color: $gray-100;
  }
  .body-r-container {
    padding: 10px 20px 20px;
  }
  .table-head {
    background: $gray-200;
    border-radius: 4px;
    padding: 8px;
  }
  .table-head-custom {
    background: #c8c8c8;
  }
  .t-filter-search {
    input {
      border-radius: 4px;
      border: 0px;
      height: 32px;
      max-width: 174px;
      width: 100%;
      font-size: 12px;
      color: $color_10;
      padding: 10px 10px 10px 35px;
      font-family: $font-family_6;
      background-color: $background-color_16 !important;
      padding: 12px 0px !important;
      &::placeholder {
        color: $color_7;
      }
      &:-ms-input-placeholder {
        color: $color_7;
      }
      &::-ms-input-placeholder {
        color: $color_7;
      }
    }
    .anticon {
      svg {
        fill: var(--darkBlack) !important;
      }
    }
    span {
      background-color: $background-color_16 !important;
      border: none !important;
      border-radius: 2px;
    }
    border: none !important;
  }
  .t-filter-block {
    button {
      background: transparent;
      border: 0px;
      padding: 0px;
    }
    svg {
      path {
        fill: var(--darkBlack);
      }
    }
  }
  .cus-dark-table {
    table {
      width: 100%;
      thead {
        tr {
          th {
            font-size: 12px;
            // color: $color_7;
            color: #fafafa !important;
            background: $blue-100 !important;
            font-family: $font-family_6;
            font-weight: normal;
            padding: 10px;
            white-space: nowrap;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 12px;
            font-family: $font-family_6;
            color: $color_1;
            padding: 5px 10px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          &:last-child {
            td {
              border-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .status-text {
    font-size: 12px;
    font-family: $font-family_6;
    position: relative;
    display: flex;
    align-items: center;
    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .green-status {
    color: $color_3;
    &::before {
      background-color: $background-color_8;
    }
  }
  .red-status {
    color: $color_4;
    &::before {
      background-color: $background-color_9;
    }
  }
  .action-btn {
    button {
      background: transparent;
      border: 0px;
    }
  }
  .cus-pagination {
    display: flex;
    justify-content: flex-end;
  }
  .page-item.disabled {
    .page-link {
      background: transparent;
      svg {
        path {
          fill: #484848;
        }
      }
    }
  }
  .page-link {
    background-color: $background-color_1;
    border: 0px;
    font-size: 12px;
    color: $color_1;
    font-family: $font-family_6;
    border-radius: 8px;
    &:hover {
      background-color: $background-color_10;
      color: $color_1;
    }
  }
  .pro-menu {
    a {
      font-size: 12px;
      color: $color_1;
      font-family: $font-family_2;
      display: flex;
      align-items: center;
      padding: 15px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      .d-pro-icon {
        margin-right: 10px;
      }
    }
    li {
      &:first-child {
        a {
          padding-top: 0px;
          border-top: 0px;
        }
      }
      &:last-child {
        a {
          padding-bottom: 0px;
          border-bottom: 0px;
        }
      }
    }
    padding: 0px;
    background: var(--whiteShade-100);
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    min-width: 12.5rem;
  }
  .dropdown-item.active {
    background-color: $background-color_1;
    color: $color_1;
  }
  .dropdown-item {
    &:active {
      background-color: $background-color_1;
      color: $color_1;
    }
    &:focus {
      background-color: $background-color_1;
      color: $color_1;
    }
    &:hover {
      background-color: $background-color_1;
      color: $color_1;
    }
  }
  .dark-head-card {
    // background: #e4e4e4;
    background: #fff;
    // background: var(--whiteShade-100);
    border-radius: 10px;
    box-shadow: 0 4px 8px #2a3f54;
  }
  .d-c-head {
    padding: 20px;
    border-bottom: 2px solid #b6b6b6;
  }
  .d-c-body {
    padding: 20px;
  }
  .cus-floating-inputs {
    .form-control {
      height: 46px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      background: transparent;
      font-family: $font-family_6;
      font-size: 14px;
      color: $color_1;
      font-size: 12px;
    }
    select.form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
  .cus-floating-inputs.form-floating {
    > label {
      padding: 0.6rem 0.75rem;
      color: $color_7;
      font-family: $font-family_6;
      font-size: 14px;
      padding: 0px;
      transform: scale(1) translateY(0.7rem) translateX(0.95rem);
      background-color: $background-color_7;
      height: auto;
      opacity: 1;
    }
    > .form-control {
      &:focus {
        ~ {
          label {
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            background-color: $background-color_7;
            opacity: 1;
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            opacity: 1;
          }
        }
      }
      &:not(:placeholder-shown) {
        ~ {
          label {
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            background-color: $background-color_7;
            opacity: 1;
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            opacity: 1;
          }
        }
      }
    }
    > .form-select {
      ~ {
        label {
          transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
          height: auto;
          background-color: $background-color_7;
          opacity: 1;
          transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
          height: auto;
          opacity: 1;
        }
      }
    }
  }
  .form-floating {
    > .form-control {
      &:focus {
        padding-top: 0.525rem;
        padding-bottom: 0.625rem;
      }
      &:not(:placeholder-shown) {
        padding-top: 0.525rem;
        padding-bottom: 0.625rem;
      }
    }
  }
  .form-select {
    &:focus {
      box-shadow: none;
    }
  }
  .dark-modal {
    .modal-content {
      background: var(--whiteShade-100);
    }
  }
  .s-ani-img {
    max-width: 100px;
    height: 100px;
    width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .suc-m-block {
    h4 {
      font-size: 18px;
      color: $color_1;
      letter-spacing: 0.05em;
      text-align: center;
    }
    p {
      font-size: 14px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .suc-m-body {
    padding: 50px 30px 30px;
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 15px;
    button {
      background-color: $background-color_1;
      border: 0px;
      padding: 0px;
    }
  }
  .modal-backdrop.show {
    background: linear-gradient(
      116.01deg,
      rgba(47, 50, 53, 0.66) 0.84%,
      rgba(33, 37, 40, 0.66) 16%,
      rgba(0, 0, 0, 0.66) 45.27%
    );
    backdrop-filter: blur(2px);
    opacity: 1;
  }
  .m-head {
    h3 {
      font-size: 18px;
      color: $color_1;
      letter-spacing: 0.05em;
      font-family: $font-family_2;
    }
  }
  .error-msg {
    color: $color_2;
  }
  .opacity04 {
    opacity: 0.4;
  }
  .modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px;
  }
  hr {
    &:not([size]).black-hr {
      height: 2px;
      background: var(--pureWhite);
      opacity: 1;
    }
    &:not([size]).black-light-hr {
      height: 2px;
      background: var(--whiteShade-100);
      opacity: 1;
    }
  }
  .cus-up-card {
    border: 1px dashed;
    border-radius: 4px;
    min-height: 305px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    label {
      position: absolute;
      top: -9px;
      left: 20px;
      background: var(--whiteShade-100);
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .up-con {
    text-align: center;
    h5 {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .b-block {
    position: relative;
    color: $color_6;
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0;
      cursor: pointer;
      z-index: 9;
    }
  }
  .up-name-card {
    border: 1px dashed;
    border-radius: 4px;
    padding: 15px;
    max-width: 250px;
    width: 100%;
    display: flex;
  }
  .f-text {
    flex: 1;
    padding-left: 15px;
    a {
      font-size: 12px;
      color: $color_6;
      font-family: $font-family_6;
      display: block;
    }
    small {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .mo-wrap {
    white-space: nowrap;
  }
  .mr-b8 {
    margin-bottom: 8px;
  }
  .label-title {
    font-size: 12px;
    color: $color_7;
    font-family: $font-family_6;
  }
  .satic-form-group {
    line-height: 0;
  }
  .cus-toggle-switch {
    label {
      margin: 0px;
      cursor: pointer;
      input {
        display: none;
        &:checked {
          ~ {
            span {
              background: #45a717;
              &::after {
                transform: translateY(-50%) translateX(12px);
                transition: all 0.3s linear 0s;
                border: 1px solid rgba(82, 156, 70, 1);
              }
            }
          }
        }
      }
      span {
        width: 28px;
        height: 16px;
        border-radius: 50px;
        background: var(--grey);
        display: block;
        position: relative;
        &::after {
          content: "";
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: var(--darkBlack);
          left: 0px;
          top: 50%;
          transform: translateY(-50%) translateX(1px);
          transition: all 0.3s linear 0s;
          position: absolute;
          border: 1px solid var(--darkBlack);
        }
      }
    }
  }
  .nav-tabs {
    .nav-link {
      font-size: 18px;
      letter-spacing: 0.05em;
      font-family: $font-family_2;
      position: relative;
      display: block;
      overflow: hidden;
      padding: 10px 0px;
      margin-right: 30px;
      background-color: $background-color_1;
      border: 0px;
      color: $color_7;
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background: #0177fb;
        position: absolute;
        left: 0px;
        bottom: 0px;
        transform: translateY(2px);
        transition: all 0.3s linear 0s;
      }
    }
    .nav-item {
      &:last-child {
        .nav-link {
          margin-right: 0px;
        }
      }
    }
    .nav-item.show {
      .nav-link {
        background-color: $background-color_1;
        border: 0px;
        color: $color_7;
        color: $color_1;
        &::after {
          transform: translateY(-1px);
          transition: all 0.3s linear 0s;
        }
      }
    }
    .nav-link.active {
      background-color: $background-color_1;
      border: 0px;
      color: $color_7;
      color: $color_1;
      &::after {
        transform: translateY(-1px);
        transition: all 0.3s linear 0s;
      }
    }
    border-bottom: 0px;
  }
  .domains-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }
  .domains-c-head {
    background: rgb(216 216 216);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    h5 {
      font-size: 12px;
      color: $color_1;
      font-family: $font-family_6;
    }
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }
  .domains-card-body {
    padding: 12px;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
    h4 {
      font-size: 20px;
      color: $color_1;
      font-family: $font-family_2;
      span {
        font-size: 12px;
        color: $color_7;
        font-family: $font-family_6;
        margin-left: 12px;
      }
    }
  }
  .dom-drop-box {
    min-width: 134px;
    background: var(--whiteShade-100);
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 0px;
    li {
      .dropdown-item {
        .da-icon {
          display: flex;
          align-items: center;
          margin-right: 10px;
        }
        font-size: 12px;
        color: $color_1;
        font-family: $font-family_2;
        display: flex;
        align-items: center;
        padding: 15px 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
      padding: 0px 15px;
      &:last-child {
        .dropdown-item {
          border-bottom: 0px;
        }
      }
    }
  }
  .ed-map-block {
    iframe {
      width: 100%;
      height: 234px;
      border-radius: 10px;
    }
  }
  .cus-pro-bar {
    .progress {
      background: #35383b;
      border-radius: 30px;
      height: 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
    .progress-bar {
      background: #45a717;
      border-radius: 50px;
      height: 4px;
    }
    .pro-value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 10px;
        color: $color_1;
        font-family: $font-family_2;
      }
    }
  }
  .a-prog-ani {
    .progress {
      height: 12px;
      background: var(--grey);
      border-radius: 50px;
    }
    .progress-bar {
      background: rgb(42, 46, 49);
      background: -moz-linear-gradient(
        left,
        rgba(42, 46, 49, 1) 0%,
        rgba(1, 119, 251, 1) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(42, 46, 49, 1) 0%,
        rgba(1, 119, 251, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(42, 46, 49, 1) 0%,
        rgba(1, 119, 251, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--whiteShade-100)', endColorstr='#0177fb', GradientType=1);
      border-radius: 50px;
    }
  }
  .cus-r-btn {
    font-size: 12px;
    color: $color_1;
    font-family: $font-family_2;
    label {
      display: flex;
      align-items: center;
    }
    input {
      display: none;
      &:checked {
        ~ {
          span {
            border: 2px solid #0177fb;
            &::after {
              content: "";
              width: 8px;
              height: 8px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background: #0177fb;
              border-radius: 50%;
            }
          }
        }
      }
    }
    span {
      display: block;
      width: 16px;
      height: 16px;
      border: 2px solid #8c8c8c;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;
    }
  }
  .cus-c-btn {
    font-size: 12px;
    color: $color_1;
    font-family: $font-family_2;
    label {
      display: flex;
      align-items: center;
    }
    input {
      display: none;
      &:checked {
        ~ {
          span {
            border: 2px solid #0177fb;
            background-color: $background-color_2;
            &::after {
              content: "L";
              position: absolute;
              transform: scalex(-1) rotate(-397deg);
              transform-origin: center;
              font-size: 12px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    span {
      display: block;
      width: 16px;
      height: 16px;
      border: 1px solid #8c8c8c;
      position: relative;
      margin-right: 10px;
      border-radius: 2px;
    }
  }
  .ds-board {
    height: calc(100vh - 9.8em);
    display: flex;
    flex-wrap: wrap;
  }
  .ds-search-col {
    max-width: 284px;
    width: 100%;
    position: relative;
  }
  .ds-resuld-col {
    flex: 1;
    border-radius: 4px;
    position: relative;
  }
  .ds-serach-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 10px;
    input {
      background: transparent;
      border: 0px;
      font-size: 12px;
      font-family: $font-family_6;
      box-sizing: border-box;
      color: $color_1;
      flex: 1;
      padding-right: 61px;
      width: 100%;
      &::placeholder {
        color: $color_7;
      }
      &:-ms-input-placeholder {
        color: $color_7;
      }
      &::-ms-input-placeholder {
        color: $color_7;
      }
    }
    button {
      background-color: $background-color_1;
      border: 0px;
      padding: 0px 15px;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      line-height: 0;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  .ds-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .list-block {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: var(--grey);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $background-color_7;
      border: 1px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }
    ul {
      padding: 0px;
      margin: 0px;
      li {
        list-style-type: none;
        font-size: 12px;
        color: $color_1;
        font-family: $font-family_6;
        padding: 8px 10px;
        border-bottom: 1px solid gray;
        &:hover {
          background: rgba(255, 255, 255, 0.05);
          cursor: pointer;
        }
      }
      li.list-active {
        background: rgba(255, 255, 255, 0.05);
        cursor: pointer;
      }
    }
  }
  .ds-r-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ds-r-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .b-card-row {
    flex: 1;
    overflow: auto;
  }
  .ds-r-head {
    h3 {
      font-size: 16px;
      color: $color_1;
      font-family: $font-family_2;
      margin-bottom: 15px;
    }
    .nav-tabs {
      .nav-link {
        font-size: 14px;
      }
    }
  }
  .info-black-card {
    // background: var(--pureWhite);
    padding: 20px;
  }
  .info-gray-card {
    background: var(--whiteShade-100);
    padding: 20px;
  }
  .mr-b15 {
    margin-bottom: 15px;
  }
  .over-wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: var(--whiteShade-100);
    border-radius: 4px;
  }
  .cus-scroll {
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: var(--grey);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $background-color_7;
      border: 1px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }
  }
  .ds-foot {
    padding: 30px 0px 0px;
  }
  .m-d-list {
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        margin-bottom: 10px;
      }
    }
  }
  .tls-msg-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
  }
  .policies-block {
    .tls-msg-card {
      background-color: $background-color_1;
    }
  }
  .n-list-wrap {
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        padding: 12px 20px;
        font-family: $font-family_2;
        font-size: 12px;
        color: $color_1;
        margin-bottom: 10px;
      }
    }
  }
  .gray-box {
    border-radius: 4px;
    border-radius: 4px;
    background: var(--whiteShade-100);
  }
  .black-card {
    background: var(--pureWhite);
  }
  .val-pro-box {
    max-width: 191px;
    width: 100%;
    .red-bar {
      background: #dc3545;
    }
  }
  .price-card {
    width: 100%;
    background: var(--whiteShade-100);
    border-radius: 4px;
  }
  .price-c-head {
    padding: 25px;
  }
  .price-card-body {
    padding: 25px;
  }
  .price-list {
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        font-size: 12px;
        color: $color_1;
        font-family: $font-family_6;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .green-tick {
    &::before {
      content: "";
      width: 100%;
      max-width: 24px;
      flex: 0 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .cross-tick {
    &::before {
      content: "";
      width: 100%;
      max-width: 24px;
      flex: 0 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("../images/cross.png") no-repeat center;
    }
  }
  .blue-c-head {
    background: #0177fb;
    border: 1px solid #0177fb;
    border-radius: 4px;
    position: relative;
  }
  .batch-note {
    background: #d8eaff;
    border-radius: 2px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    min-width: 112px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: 30px;
  }
  .pay-m-block {
    padding: 15px;
    background: var(--whiteShade-100);
    max-width: 279px;
    width: 100%;
    margin-top: 15px;
    ul {
      padding: 0px;
      margin: 0px;
      li {
        list-style-type: none;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        padding: 15px;
      }
    }
  }

  .t-strip {
    background: #d8eaff;
    padding: 10px;
    margin: 10px -10px;
  }
  .order-block {
    margin-top: 15px;
  }
  .breack-list {
    ul {
      padding: 0px;
      margin: 0px;
      max-width: 263px;
      width: 100%;
      li {
        background: #212528;
        padding: 10px;
        list-style-type: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        h5 {
          font-size: 12px;
          color: $color_1;
          font-family: $font-family_6;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &:last-child {
          background: #d8eaff;
          h5 {
            font-family: $font-family_2;
            color: $color_6;
          }
        }
      }
    }
  }
  .chart-block {
    img {
      width: 100%;
      height: auto;
    }
  }
  .h-80 {
    max-height: 400px;
    overflow-x: auto;
  }
  .ant-table-wrapper {
    background-color: $gray-100 !important;
  }
  .ant-table {
    background-color: $background-color_6 !important;
    background-color: $background-color_1 !important;
    th {
      color: $color_11 !important;
      font-size: 12px;
      font-family: $font-family_6;
      font-weight: 200 !important;
    }
    td {
      border-bottom: 1px solid #c8c8c8 !important;
      color: $color_1 !important;
      font-size: 12px;
      font-family: $font-family_6 !important;
      padding: 1px !important;
    }
  }
  .ant-table-tbody {
    border: 1px solid;
  }
  .ant-table-cell {
    padding: 0px 4px !important;
    background: $gray-100 !important;
    border: none !important;
    &::before {
      display: none !important;
    }
  }
  .ant-table-container {
    border-radius: 0px !important;
  }
  .ant-table-thead {
    &::before {
      // background-color: $background-color_6 !important;
      background: $blue-100 !important;
    }
    > tr {
      > th {
        padding: 10px 4px !important;
        color: #000 !important;
        // border: 1px solid #c6c7c8  !important;

        border-top: 1px solid #c6c7c8 !important;
        border-left: 1px solid #c6c7c8 !important;
        border-right: 1px solid #c6c7c8 !important;
        background: #fff !important;
        border-radius: 0px !important;
        // color: #fafafa !important;
        // background: $blue-100 !important;
      }
      > td {
        background: $blue-100 !important;
      }
    }
  }
  &.ant-table-wrapper {
    .ant-table-container {
      table {
        > thead {
          > tr {
            &:first-child {
              > * {
                &:first-child {
                  border-start-start-radius: 0px !important;
                }
                &:last-child {
                  border-start-end-radius: 0px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .rc-virtual-list-holder-inner {
    background-color: $background-color_11;
  }
  .ant-select-dropdown {
    background-color: $background-color_11;
    border: 1px solid #bdbdbd !important;
    border-top: none !important;
    border-radius: 0 0 4px 4px !important;
    margin-top: 0 !important;
    box-shadow: none !important;
    background: var(--darkBlue-300);
    border: 1px solid var(--darkBlue-300) !important;
    border-radius: 4px;
  }
  .text-white {
    color: $color_1 !important;
  }
  .MuiIconButton-root {
    svg {
      fill: var(--darkBlack) !important;
    }
  }
  .page-header {
    font-family: $font-family_8;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: $color_1;
  }
  .add-btn {
    background: #0177fb;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .search {
    width: 200px;
  }
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: -1px 5px;
  }
  .bg-green {
    background-color: $background-color_12;
  }
  .text-success {
    color: $color_12 !important;
  }
  .bg-red {
    background-color: $background-color_13;
  }
  .swal2-popup {
    // background: var(--pureWhite) !important;
    color: $color_1 !important;
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  .icon {
    color: $color_1;
    font-size: 20px;
  }
  .ant-pagination-item {
    color: $color_1;
  }
  .ant-pagination-item-link {
    color: $color_1 !important;
  }
  .ant-pagination-item-ellipsis {
    color: $color_1 !important;
  }
  .ant-pagination {
    .ant-pagination-item-active {
      background-color: $background-color_14;
      border-color: $border-color_1;
    }
  }
  .ant-select-selector {
    background-color: $background-color_14 !important;
    border-color: $border-color_1 !important;
    color: $color_7 !important;
    border: 1px solid #d4b8b8af !important;
    border-color: $border-color_2 !important;
    border-radius: 4px !important;
    height: 43px !important;
    padding: 6px 8px !important;
    overflow: hidden !important;
    border: 1px solid var(--grey) !important;
  }
  .input-affix-wrapper {
    background-color: $background-color_15 !important;
    input {
      background-color: $background-color_15 !important;
      color: $color_1 !important;
    }
  }
  .dark-card {
    width: 100%;
    height: 100%;
    background: #2a2e31;
    padding: 10px;
  }
  .bulk-btn {
    width: 105px;
    height: 32px;
    background: #d8eaff;
    border-radius: 4px;
    font-family: $font-family_8;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $color_6;
    padding: 6px 10px;
  }
  .ant-empty-description {
    color: $color_1 !important;
  }
  .cursor {
    cursor: pointer;
  }
  .ant-table-tbody {
    > tr.ant-table-row {
      &:hover {
        > td {
          background-color: $background-color_17 !important;
        }
      }
    }
    > tr {
      > td.ant-table-cell-row-hover {
        background-color: $background-color_17 !important;
      }
    }
  }
  .ant-select {
    width: 100%;
  }
  .ant-select-dropdown-menu-item {
    color: $color_13 !important;
  }
  .ant-select-selection-item-content {
    color: $color_14;
    font-size: 10px;
    color: $color_1;
    font-family: $font-family_2;
    background: var(--pureWhite) !important;
  }
  .anticon-close {
    svg {
      fill: #fff !important;
      color: $color_14;
    }
  }
  .ant-select-selection-placeholder {
    color: $color_15;
    font-size: 14px;
    font-family: $font-family_6;
    color: $color_7 !important;
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: var(--darkBlack);
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 12px;
  }
  label[data-shrink="false"] {
    + {
      .MuiInputBase-formControl {
        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
          height: 10px !important;
        }
      }
    }
  }
  .error {
    span {
      color: $color_16;
      font-family: $font-family_9;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      text-align: left;
      margin-top: 3px;
      margin-right: 14px;
      margin-bottom: 0;
      margin-left: 14px;
    }
  }
  &:where(.css-dev-only-do-not-override-diro6f).ant-menu-light {
    font-size: 14px;
    color: $color_9;
    font-family: $font-family_6;
    display: flex;
    align-items: center;
    background: #2a2e31 !important;
  }
  .grey-border {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    svg {
      fill: #1f1f1f;
    }
  }
  .text-grey {
    span {
      font-size: 12px;
      font-family: $font-family_6;
      color: $color_1 !important;
      padding: 5px 10px;
    }
  }
  .bg-black {
    background-color: $background-color_6;
    padding: 10px;
  }
  .bg-grey {
    background: #35383b !important;
  }
  td {
    color: $color_1;
  }
  th {
    color: $color_1;
  }
  &:where(.css-dev-only-do-not-override-diro6f).ant-select-multiple {
    .ant-select-selection-placeholder {
      font-size: 14px;
      font-family: $font-family_6;
      color: $color_15 !important;
    }
    .ant-select-selection-item {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50px;
      padding: 0px 8px;
      border: 0px;
    }
    .ant-select-selection-item-remove {
      line-height: 17px;
    }
  }
  &:where(.css-dev-only-do-not-override-diro6f).ant-select-dropdown {
    .ant-select-item {
      font-size: 12px;
      font-family: $font-family_2;
      color: $color_1;
      margin: 2px 0px;
    }
    .ant-select-item-option-selected {
      &:not(.ant-select-item-option-disabled) {
        font-weight: normal;
      }
    }
  }
  .ant-select-item-option-selected {
    background: rgba(255, 255, 255, 0.2) !important;
  }
  .ant-menu {
    .ant-menu-root {
      background: "#2a2e31" !important;
    }
  }
  .active {
    color: $color_6 !important;
  }
  .ant-select-selection-item {
    color: $color_1;
  }
  .submit-Button {
    background-color: $background-color_2 !important;
  }
  .custom-cancel-button {
    background-color: $background-color_3 !important;
    color: $color_6 !important;
  }
  .switch-label {
    font-family: $font-family_6;
    font-weight: 400;
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
    // color: $color_15;
  }
  .span-title {
    font-family: $font-family_6;
    font-weight: 700;
    font-size: 14px;
    color: $color_1;
  }
  .dark-mode-editor {
    .jodit_container {
      background-color: $background-color_18;
      color: $color_14;
    }
    .jodit_toolbar {
      .jodit_toolbar_btn {
        color: $color_14;
      }
    }
  }
  .jodit_theme_dark {
    .jodit-toolbar__box {
      &:not(:empty) {
        background: #2a2e31;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
      }
    }
    .jodit-wysiwyg {
      background: #2a2e31;
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
    }
    .jodit-workplace {
      + {
        .jodit-status-bar {
          &:not(:empty) {
            background-color: $background-color_15;
            border-color: $border-color_3;
            color: $color_17;
            border: 1px solid rgba(255, 255, 255, 0.1) !important;
          }
        }
      }
    }
  }
  .jodit-container {
    &:not(.jodit_inline) {
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
    }
  }
  .notification {
    background: var(--whiteShade-100);
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 20px;
    margin-top: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: $color_1;
    }
  }
  .text-link {
    font-family: $font-family_6;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    cursor: pointer;
    color: $color_6;
  }
  .heading-label {
    font-family: $font-family_6;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color_7;
  }
  .heading-value {
    font-family: $font-family_6;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: $color_1;
  }
  .outline-effect {
    border-radius: 4px;
    border: 1px solid rgba(71, 66, 66, 0.411);
    padding: 2px;
  }
  .outline-label {
    background-color: #e4e4e4 !important;

    color: #ffffff;
  }
  .d-c-head-black {
    background: #f7f9fb;
    margin: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  .heading {
    color: $color_18;
    font-size: 12px;
    font-family: $font-family_6;
    line-height: 18px;
    margin-bottom: 20px;
  }
  .nest-table {
    .ant-table-cell {
      background-color: $background-color_15;
      border-bottom: 1px solid gray !important;
      padding: 0px 4px !important;
    }
    th {
      color: $color_19 !important;
      font-size: 12px;
      font-family: $font-family_6;
      font-weight: 400 !important;
      padding-left: 10px;
    }
    &.ant-table-wrapper {
      .ant-table-container {
        table {
          > thead {
            > tr {
              &:first-child {
                > * {
                  &:first-child {
                    border-start-start-radius: 0px;
                  }
                  &:last-child {
                    border-start-end-radius: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
    td {
      color: $color_1 !important;
      font-size: 12px;
      font-family: $font-family_6;
      border-bottom: 1px solid var(--darkBlack) 66 !important;
    }
  }
  .tab-label {
    color: $color_7;
    font-size: 14px;
    font-family: $font-family_6;
    font-weight: 700;
    letter-spacing: 0.7px;
    padding: 10px;
    cursor: pointer;
  }
  .tab-active {
    color: $color_1;
    border-bottom: 2px solid #0177fb;
    cursor: pointer;
  }
  .note {
    color: $color_20;
    font-size: 12px;
    font-family: $font-family_6;
    font-weight: 500;
  }
  .fw-800 {
    font-weight: 800;
  }
  .ant-modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    padding-top: 10px;
  }
  .swal2-html-container {
    color: $color_7;
    text-align: center;
    font-size: 14px;
    font-family: $font-family_6;
    letter-spacing: 0.7px;
  }
  .swal2-title {
    color: $color_1;
    font-size: 18px;
    font-family: $font-family_6;
    font-weight: 700;
    letter-spacing: 0.9px;
  }
  .swal2-confirm {
    background: #0177fb !important;
    color: $color_14;
    text-align: center;
    font-size: 14px;
    font-family: $font-family_6;
    font-weight: 500;
  }
  .high {
    color: $color_4;
  }
  .label-white {
    color: $color_1;
    font-size: 12px;
    font-family: $font-family_6;
    display: block;
  }
  .label-gray {
    color: $color_7;
    font-size: 12px;
    font-family: $font-family_6;
    display: block;
  }
  .f-700 {
    font-weight: 700;
  }
  .c-green {
    color: $color_12;
  }
  .span-gray {
    span {
      color: $color_7;
      font-size: 12px;
      font-family: $font-family_6;
    }
  }
  .b-black {
    background-color: $background-color_6;
  }
  .dropdown-menu {
    background: #e4e4e4;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  .border-bottom-1 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .dropdown-divider {
    margin: 5px;
  }
  .feature {
    background-color: $background-color_19;
    border-bottom: 1px solid;
  }
  .c-sky {
    background-color: $background-color_3;
  }
  .text-blue {
    color: $color_6;
    font-size: 12px;
    font-family: $font-family_6;
    font-style: normal;
    font-weight: 700;
  }
  .border-bottom-gray {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .plan-card {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
    padding: 20px;
  }
  .fs-18 {
    font-size: 18px;
    font-family: $font-family_6;
  }
  .fs-32 {
    font-size: 32px;
    font-family: $font-family_6;
  }
  .fs-12 {
    font-size: 12px;
    font-family: $font-family_6;
  }
  .f-gray {
    color: $color_20;
    font-size: 12px;
    font-family: $font-family_6;
  }
  .ant-modal-content {
    background: $background-color_7 !important;
  }
  .ant-modal-header {
    background-color: $background-color_7;
  }

  .ant-modal-body {
    background-color: $background-color_7;
  }
  .ant-input {
    color: $color_1 !important;
  }
  .ant-badge {
    svg {
      path {
        fill: #1f1f1f;
      }
    }
  }
  @media (max-width: 768px) {
    .login-wrap {
      padding: 10px;
    }
    .l-inn-content {
      padding: 20px;
    }
    .login-box {
      &::after {
        display: none;
      }
      h3 {
        font-size: 18px;
      }
      h5 {
        font-size: 13px;
      }
    }
    .l-btm-btn {
      text-align: center;
      button {
        height: 40px;
        font-size: 11px;
        padding: 0px 20px;
      }
    }
    .f-r-block {
      a {
        font-size: 12px;
      }
      .r-cus-check {
        font-size: 12px;
      }
    }
    .l-s-text {
      font-size: 12px;
    }
    .l-b-text {
      font-size: 10px;
    }
  }
  .dashboard-graph {
    background-color: var(--whiteShade-100);
    box-shadow: 0 4px 8px #2a3f54;
  }
  .dashboard-graph1 {
    background-color: var(--whiteShade-100);
  }

  .graph-title {
    color: var(--darkBlack);
  }

  .apexcharts-legend-text {
    color: var(--darkBlack) !important;
  }
  .ant-modal-close svg path {
    fill: $color_8;
  }
  .dropdown-toggle::after {
    color: $color_8;
  }
  .anticon-search svg {
    fill: #151a2c;
  }
  .ant-input-group-addon button {
    border-left: 1px solid #151a2c;
  }
  .MuiSelect-nativeInput {
    display: none !important;
  }

  .inherit-nest .ant-table-cell {
    // background-color: var(--whiteShade-100) !important;
  }

  .inherit-nest .ant-table-container {
    background-color: var(--whiteShade-100) !important;
  }

  .inherit-nest .ant-spin-container {
    background-color: var(--whiteShade-100) !important;
  }
  .threat-hunting-legend {
    color: #bebfc1;
    // font-family: Inter-Regular;
    // font-size: 14px;
    // font-weight: 100;
  }
  .threat-hunting-fieldset {
    border-color: #bebfc1;
  }
  .table-head-white {
    background: #bebfc1;
  }
  .white-heading-tread-hunting {
    color: #bebfc1;
  }
  .ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
    background-color: #9697a1;
  }
  .addEditTestLabel {
    color: black;
  }
  .addEditTestDesc {
    color: black;
  }
  .colorLabel {
    color: black;
  }
  .console_detail {
    color: $color_13;
  }
  .test_details_inner_collapse {
    background-color: $background-color_16;
  }
  @media (min-width: 576px) {
    .modal-dialog.dialog450 {
      max-width: 450px;
      margin: 1.75rem auto;
    }
  }
}
.blue-theme {
  $color_1: #ffffff;
  $color_2: #dc3545;
  $color_3: #01b8aa;
  $color_4: #fe7750;
  $color_5: #f0c555;
  $color_6: #0177fb;
  $color_7: rgba(255, 255, 255, 0.4);
  $color_8: #212121;
  $color_9: #a5b3cd;
  $color_10: rgba(255, 255, 255, 1);
  $color_11: #333;
  $color_12: #fff;
  $color_13: #d32f2f;
  $color_14: #d1cccc;
  $color_15: var(--white-100, #fff);
  $color_16: #ffffff66;
  $color_17: var(--white-40, rgba(255, 255, 255, 0.4));
  $color_18: green;
  $blueTheme: #2d2f42;
  $font-family_1: Inter-Black;
  $font-family_2: Inter-Bold;
  $font-family_3: Inter-ExtraBold;
  $font-family_4: Inter-Light;
  $font-family_5: Inter-Medium;
  $font-family_6: Inter-Regular;
  $font-family_7: Inter-Thin;
  $font-family_8: "Inter";
  $font-family_9: "Roboto", "Helvetica", "Arial", sans-serif;
  $background-color_1: transparent;
  $background-color_2: #0177fb;
  $background-color_3: #d8eaff;
  $background-color_4: #dc3545;
  $background-color_5: #212121;
  $background-color_6: var(--darkBlue-100);
  $background-color_7: var(--darkBlue-200);
  $background-color_8: #01b8aa;
  $background-color_9: #fe7750;
  $background-color_10: rgba(255, 255, 255, 0.05);
  $background-color_11: green;
  $background-color_12: red;
  $background-color_13: inherit;
  $background-color_14: #2a2e31;
  $background-color_15: gray;
  $background-color_16: #333;
  $background-color_17: #212528;
  $background-color_18: var(--lightBlue);
  $border-color_1: #1677ff;
  $border-color_2: rgba(255, 255, 255, 0.1);
  $border-color_3: #6b6b6b;

  .card-text-white h5 {
    color: $background-color_3;
  }
  .card-icon {
    color: $color_12;
  }
  .form-label {
    color: #ffffff;
  }
  /* all margin */
  /* margin top */
  /* margin right */
  /* margin bottom */
  /* margin left */
  /* padding  */
  /* padding top */
  /* padding right */
  /* padding bottom */
  /* padding left */
  /* Login css  */
  /* login media css  */
  /* Sidebar css */
  /* Track */
  /* Handle */
  /* Track */
  /* Handle */
  /* New CSS  */
  /* .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.css-mli2yg-MuiFormControl-root-MuiTextField-root .MuiInputLabel-root,
.MuiFormLabel-root {
  font-family: Inter-Regular;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
}

.css-14lo706 > span {
  color: rgba(255, 255, 255, 0.4);
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(18px, -7px) scale(0.85);
  -webkit-transform: translate(18px, -7px) scale(0.85);
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  font-size: 12px;
  color: #ffffff !important;
  font-family: Inter-Bold;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(15px, 13px) scale(1);
}

.css-mli2yg-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
  font-family: Inter-Regular;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #ffffff !important;
  font-size: 12px;
  font-family: Inter-Bold;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 11px 14px !important;
}

.css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.1);
} */
  /* .MuiSelect-icon path {
  display: none;
}

.MuiSelect-icon {
  background: url("../images/w-d-icon.png") no-repeat center;
} */
  /* .ant-table {
    background-color: #2a2e31;
  } */
  /* .ant-table-thead th {
  color: #ffffff !important;
  background-color: #2a2e31 !important;
  border: none !important;
} */
  /* input {
    color: #ffffff !important;
    height: 12px !important;
  } */
  /* .MuiFormLabel-root {
  padding: 0.6rem 0.75rem;
  color: rgba(255, 255, 255, 0.4);
  font-family: Inter-Regular;
  font-size: 14px;
  padding: 0px;
  background-color: #2a2e31;
  height: auto;
  opacity: 1;
} */
  /* Define styles for the Select component */
  /* Define styles for the input element */
  /* .ant-select-selector input {
    border: none !important;
    outline: none !important;
  } */
  /* Define styles for the control wrapper */
  /* Define styles for the focused state of the control wrapper */
  /* .ant-select-focused .ant-select-selector {
    border-color: #3f51b5 !important;
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2) !important;
  } */
  /* Define styles for the selected option */
  /* .ant-select-selection-item {
    color: #3f51b5 !important;
  } */
  /* Define styles for the menu */
  /* Define styles for the menu options */
  /* Define styles for the selected menu option */
  /* .ant-select-item-option-selected {
    background-color: #3f51b5 !important;
    color: #fff !important;
  } */
  /* Add more custom styles as needed */
  /* .bg-blue {
  background-color: #0177fb;
} */
  .highcharts-legend-item text {
    fill: white !important;
  }
  .highcharts-axis-labels text {
    fill: white !important;
  }

  .footer {
    background: $blueTheme;
  }
  body {
    background: var(--darkBlue-100);
  }
  .ruleset-add-btn,
  .container-add {
    color: white !important;
  }
  .btn-secondary {
    &:hover {
      background-color: $background-color_1;
    }
    &:focus {
      background-color: $background-color_1;
    }
  }
  .ant-radio-wrapper-disabled span {
    color: #9697a1;
  }
  a {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    text-decoration: none;
    opacity: 1;
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
  }
  button {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    text-decoration: none;
    opacity: 1;
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
  }
  .MuiSelect-nativeInput {
    display: none;
  }
  input {
    // display: none;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    text-decoration: none;
    opacity: 1;
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      -ms-box-shadow: none !important;
      -o-box-shadow: none !important;
      text-decoration: none;
      opacity: 1;
    }
  }
  h1 {
    margin: 0px;
    padding: 0px;
  }
  h2 {
    margin: 0px;
    padding: 0px;
  }
  h3 {
    margin: 0px;
    padding: 0px;
  }
  h4 {
    margin: 0px;
    padding: 0px;
  }
  h5 {
    margin: 0px;
    padding: 0px;
  }
  h6 {
    margin: 0px;
    padding: 0px;
  }
  p {
    margin: 0px;
    padding: 0px;
  }
  .mr0 {
    margin: 0px;
  }
  .mr10 {
    margin: 10px;
  }
  .mr20 {
    margin: 20px;
  }
  .mr30 {
    margin: 30px;
  }
  .mr40 {
    margin: 40px;
  }
  .mr50 {
    margin: 50px;
  }
  .mr60 {
    margin: 60px;
  }
  .mr70 {
    margin: 70px;
  }
  .mr80 {
    margin: 80px;
  }
  .mr90 {
    margin: 90px;
  }
  .mr100 {
    margin: 100px;
  }
  .mr-t0 {
    margin-top: 0px;
  }
  .mr-t10 {
    margin-top: 10px;
  }
  .mr-t20 {
    margin-top: 20px;
  }
  .mr-t30 {
    margin-top: 30px;
  }
  .mr-t40 {
    margin-top: 40px;
  }
  .mr-t50 {
    margin-top: 50px;
  }
  .mr-t60 {
    margin-top: 60px;
  }
  .mr-t70 {
    margin-top: 70px;
  }
  .mr-t80 {
    margin-top: 80px;
  }
  .mr-t90 {
    margin-top: 90px;
  }
  .mr-t100 {
    margin-top: 100px;
  }
  .mr-r0 {
    margin-right: 0px;
  }
  .mr-r10 {
    margin-right: 10px;
  }
  .mr-r20 {
    margin-right: 20px;
  }
  .mr-r30 {
    margin-right: 30px;
  }
  .mr-r40 {
    margin-right: 40px;
  }
  .mr-r50 {
    margin-right: 50px;
  }
  .mr-r60 {
    margin-right: 60px;
  }
  .mr-r70 {
    margin-right: 70px;
  }
  .mr-r80 {
    margin-right: 80px;
  }
  .mr-r90 {
    margin-right: 90px;
  }
  .mr-r100 {
    margin-right: 100px;
  }
  .mr-b0 {
    margin-bottom: 0px;
  }
  .mr-b10 {
    margin-bottom: 10px;
  }
  .mr-b20 {
    margin-bottom: 20px;
  }
  .mr-b30 {
    margin-bottom: 30px;
  }
  .mr-b40 {
    margin-bottom: 40px;
  }
  .mr-b50 {
    margin-bottom: 50px;
  }
  .mr-b60 {
    margin-bottom: 60px;
  }
  .mr-b70 {
    margin-bottom: 70px;
  }
  .mr-b80 {
    margin-bottom: 80px;
  }
  .mr-b90 {
    margin-bottom: 90px;
  }
  .mr-b100 {
    margin-bottom: 100px;
  }
  .mr-l0 {
    margin-left: 0px;
  }
  .mr-l10 {
    margin-left: 10px;
  }
  .mr-l20 {
    margin-left: 20px;
  }
  .mr-l30 {
    margin-left: 30px;
  }
  .mr-l40 {
    margin-left: 40px;
  }
  .mr-l50 {
    margin-left: 50px;
  }
  .mr-l60 {
    margin-left: 60px;
  }
  .mr-l70 {
    margin-left: 70px;
  }
  .mr-l80 {
    margin-left: 80px;
  }
  .mr-l90 {
    margin-left: 90px;
  }
  .mr-l100 {
    margin-left: 100px;
  }
  .pd0 {
    padding: 0px;
  }
  .pd10 {
    padding: 10px;
  }
  .pd20 {
    padding: 20px;
  }
  .pd30 {
    padding: 30px;
  }
  .pd40 {
    padding: 40px;
  }
  .pd50 {
    padding: 50px;
  }
  .pd60 {
    padding: 60px;
  }
  .pd70 {
    padding: 70px;
  }
  .pd80 {
    padding: 80px;
  }
  .pd90 {
    padding: 90px;
  }
  .pd100 {
    padding: 100px;
  }
  .pd-t0 {
    padding-top: 0px;
  }
  .pd-t10 {
    padding-top: 10px;
  }
  .pd-t20 {
    padding-top: 20px;
  }
  .pd-t30 {
    padding-top: 30px;
  }
  .pd-t40 {
    padding-top: 40px;
  }
  .pd-t50 {
    padding-top: 50px;
  }
  .pd-t60 {
    padding-top: 60px;
  }
  .pd-t70 {
    padding-top: 70px;
  }
  .pd-t80 {
    padding-top: 80px;
  }
  .pd-t90 {
    padding-top: 90px;
  }
  .pd-t100 {
    padding-top: 100px;
  }
  .pd-r0 {
    padding-right: 0px;
  }
  .pd-r10 {
    padding-right: 10px;
  }
  .pd-r20 {
    padding-right: 20px;
  }
  .pd-r30 {
    padding-right: 30px;
  }
  .pd-r40 {
    padding-right: 40px;
  }
  .pd-r50 {
    padding-right: 50px;
  }
  .pd-r60 {
    padding-right: 60px;
  }
  .pd-r70 {
    padding-right: 70px;
  }
  .pd-r80 {
    padding-right: 80px;
  }
  .pd-r90 {
    padding-right: 90px;
  }
  .pd-r100 {
    padding-right: 100px;
  }
  .pd-r170 {
    padding-right: 170px;
  }
  .pd-b0 {
    padding-bottom: 0px;
  }
  .pd-b10 {
    padding-bottom: 10px;
  }
  .pd-b20 {
    padding-bottom: 20px;
  }
  .pd-b30 {
    padding-bottom: 30px;
  }
  .pd-b40 {
    padding-bottom: 40px;
  }
  .pd-b50 {
    padding-bottom: 50px;
  }
  .pd-b60 {
    padding-bottom: 60px;
  }
  .pd-b70 {
    padding-bottom: 70px;
  }
  .pd-b80 {
    padding-bottom: 80px;
  }
  .pd-b90 {
    padding-bottom: 90px;
  }
  .pd-b100 {
    padding-bottom: 100px;
  }
  .pd-l0 {
    padding-left: 0px;
  }
  .pd-l10 {
    padding-left: 10px;
  }
  .pd-l20 {
    padding-left: 20px;
  }
  .pd-l30 {
    padding-left: 30px;
  }
  .pd-l40 {
    padding-left: 40px;
  }
  .pd-l50 {
    padding-left: 50px;
  }
  .pd-l60 {
    padding-left: 60px;
  }
  .pd-l70 {
    padding-left: 70px;
  }
  .pd-l80 {
    padding-left: 80px;
  }
  .pd-l90 {
    padding-left: 90px;
  }
  .pd-l100 {
    padding-left: 100px;
  }
  .Inter-Black {
    font-family: $font-family_1;
  }
  .Inter-Bold {
    font-family: $font-family_2;
  }
  .Inter-ExtraBold {
    font-family: $font-family_3;
  }
  .Inter-Light {
    font-family: $font-family_4;
  }
  .Inter-Medium {
    font-family: $font-family_5;
  }
  .Inter-Regular {
    font-family: $font-family_6;
  }
  .Inter-Thin {
    font-family: $font-family_7;
  }

  .lh17 {
    line-height: 17px;
  }
  .h46 {
    height: 46px;
  }

  .color-white {
    color: $color_1;
  }
  .color-red {
    color: $color_2;
  }
  .color-green {
    color: $color_3;
  }
  .color-orange {
    color: $color_4;
  }
  .color-yellow {
    color: $color_5;
  }
  .color-blue {
    color: $color_6;
  }
  .blue-btn {
    background-color: $background-color_2;
    border: 1px solid #0177fb;
    border-radius: 4px;
    color: $color_1;
    text-align: center;
    &:hover {
      color: $color_6;
      background-color: $background-color_1;
    }
  }
  .gray-bor-btn {
    background-color: $background-color_1;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    text-align: center;
    color: $color_7;
  }

  .red-btn {
    background-color: $background-color_4;
    border: 1px solid #dc3545;
    border-radius: 4px;
    color: $color_1;
    text-align: center;
  }
  .black-trans-btn {
    background-color: $background-color_1;
    border: 1px solid #212121;
    border-radius: 50px;
    font-size: 16px;
    color: $color_8;
    text-align: center;
    &:hover {
      background-color: $background-color_5;
      color: $color_1;
    }
  }
  .trans-btn {
    background: transparent;
    border: 0px;
    padding: 0px;
    line-height: 0px;
  }
  .file-icon {
    line-height: 0px;
  }
  .c-icon {
    line-height: 0px;
  }
  .login-wrap {
    background-color: $background-color_6;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  .lt-logo-block {
    img {
      max-width: 105px;
      width: 100%;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }
  .login-box {
    max-width: 630px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 27em);
      height: 100vh;
      background: linear-gradient(
        269.36deg,
        rgba(42, 46, 49, 0.4) 4.22%,
        rgba(42, 46, 49, 0) 109.7%
      );
      right: 0px;
      top: 0px;
      transform: rotate(-13.5deg) translateX(-7px) translateY(0px) skewX(44deg)
        skewY(-18.4deg);
      -webkit-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      -moz-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      -ms-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      -o-transform: rotate(-13.5deg) translateX(-7px) translateY(0px)
        skewX(44deg) skewY(-18.4deg);
      z-index: 1;
      transform-origin: top right;
      -webkit-transform-origin: top right;
      -moz-transform-origin: top right;
      -ms-transform-origin: top right;
      -o-transform-origin: top right;
      pointer-events: none;
    }
    h5 {
      font-size: 16px;
      color: $color_7;
      font-family: $font-family_6;
      text-align: center;
    }
    h3 {
      font-size: 24px;
      color: $color_1;
      font-family: $font-family_2;
      text-align: center;
    }
  }
  .l-inn-content {
    width: 100%;
    background: var(--darkBlue-200);
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 70px 60px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  .l-form {
    max-width: 300px;
    width: 100%;
    margin: 40px auto 0px;
  }
  .cus-f-group {
    label.f-input-title {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
      position: absolute;
    }
    .cus-from-control {
      height: 46px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      background-color: $background-color_1;
      font-size: 12px;
      font-family: $font-family_6;
      color: $color_1;
    }
  }
  .floting-input {
    position: relative;
    label {
      position: absolute;
      top: 0px;
      left: 10px;
      padding: 0px 10px;
      background-color: $background-color_7;
      top: -9px;
    }
  }
  .f-r-block {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .r-cus-check {
    font-size: 12px;
    color: $color_7;
    font-family: $font-family_6;
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
      display: none;
      &:checked {
        ~ {
          span {
            background: #0177fb;
            border: 1px solid #0177fb;
            &::after {
              content: "L";
              position: absolute;
              width: 100%;
              height: 100%;
              font-size: 11px;
              font-family: $font-family_5;
              color: $color_1;
              transform: scaleX(-1) rotate(-48deg);
              display: inline-flex;
              align-items: center;
              top: -1px;
              justify-content: center;
              left: 0px;
            }
          }
        }
      }
    }
    span {
      width: 16px;
      height: 16px;
      display: block;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 2px;
      position: relative;
    }
  }
  .in-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 17px;
    background-color: $background-color_1;
    border: 0px;
    padding: 0px;
    line-height: 0px;
  }
  .top-bg-line {
    height: 4px;
    width: 38vw;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(37deg) translateX(12vw);
    transform-origin: center;
    background: linear-gradient(
      289.53deg,
      rgba(1, 119, 251, 0.3) 2.87%,
      rgba(1, 119, 251, 0) 99.44%
    );
    pointer-events: none;
  }
  .top-gg-line {
    height: 4px;
    width: 53vw;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(37deg) translateX(12vw);
    transform-origin: center;
    background: linear-gradient(
      289.6deg,
      var(--darkBlue-200) 5.38%,
      rgba(42, 46, 49, 0) 99.45%
    );
    pointer-events: none;
  }
  .bottom-bg-line {
    height: 4px;
    width: 52vw;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(37deg) translateX(-14vw);
    transform-origin: center;
    background: -moz-linear-gradient(
      left,
      rgba(1, 119, 251, 0) 0%,
      rgba(1, 119, 251, 0) 19%,
      rgba(1, 119, 251, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(1, 119, 251, 0) 0%,
      rgba(1, 119, 251, 0) 19%,
      rgba(1, 119, 251, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(1, 119, 251, 0) 0%,
      rgba(1, 119, 251, 0) 19%,
      rgba(1, 119, 251, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000177fb', endColorstr='#0177fb', GradientType=1);
    pointer-events: none;
  }
  .bottom-gg-line {
    height: 4px;
    width: 38vw;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(37deg) translateX(-14vw);
    transform-origin: center;
    background: linear-gradient(
      289.6deg,
      var(--darkBlue-200) 5.38%,
      rgba(42, 46, 49, 0) 99.45%
    );
    pointer-events: none;
  }
  .l-s-text {
    font-size: 14px;
    color: $color_7;
    line-height: 17px;
    letter-spacing: 0.05em;
  }
  .l-b-text {
    font-size: 12px;
    color: $color_7;
    line-height: 15px;
    letter-spacing: 0.05em;
  }
  .sidebar-wrap {
    max-width: 223px;
    width: 100%;
    background: $blueTheme;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 999;
  }
  .s-top-logo {
    max-width: 72px;
    height: auto;
    width: 100%;
    margin: 30px auto;
    img {
      width: 100%;
    }
  }
  .s-nav {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    &::-webkit-scrollbar {
      width: 0px;
    }
    h5 {
      font-size: 12px;
      color: $color_9;
      font-family: $font-family_6;
      text-transform: uppercase;
    }
  }
  .s-bm-logo {
    padding: 20px;
  }
  .nav-links-wrap {
    .dropdown-menu {
      position: static !important;
      transform: translate(0) !important;
      transition: all 0.5s linear 0s;
    }
    .dropdown-menu.show {
      transition: all 0.5s linear 0s;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        margin: 24px 0px;
        a {
          font-size: 14px;
          color: $color_9;
          font-family: $font-family_6;
          display: flex;
          align-items: center;
          .snav-icon {
            width: 20px;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
          }
        }
        &:hover {
          a {
            color: $color_6;
            stroke: #0177fb;
            svg {
              path {
                color: $color_6;
                stroke: #0177fb;
              }
            }
          }
        }
        &:first-child {
          &:hover {
            a {
              svg {
                path {
                  fill: #0177fb;
                }
              }
            }
          }
        }
      }
    }
  }
  .top-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 24px 20px;
    padding-left: 225px;
    background-color: #151a2c;
    z-index: 999;
  }
  .head-pro-block {
    display: flex;
    align-items: center;
  }
  .pro-drop-box {
    background-color: $background-color_1;
    border: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
  }
  .user-pro-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 30px;
      max-height: 30px;
      object-fit: cover;
    }
  }
  .user-pro-info {
    line-height: 7px;
    text-align: left;
    padding-left: 10px;
    h4 {
      font-size: 12px;
      color: $color_1;
      font-family: $font-family_2;
    }
    a {
      font-size: 10px;
      color: $color_1;
      font-family: $font-family_6;
    }
  }
  .dropdown-toggle {
    &::after {
      margin-left: 14px;
    }
  }
  .ntification-block {
    margin-right: 25px;
  }
  .body-wrap {
    padding-left: 225px;
    padding-top: 80px;
  }
  .body-r-container {
    padding: 10px 20px 20px;
  }
  .table-head {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    padding: 8px;
  }
  .t-filter-search {
    background-color: inherit;
    input {
      border-radius: 4px;
      border: 0px;
      height: 32px;
      max-width: 174px;
      width: 100%;
      font-size: 12px;
      color: $color_10;
      padding: 10px 10px 10px 35px;
      font-family: $font-family_6;
      background-color: $background-color_6 !important;
      padding: 12px 0px !important;
      &::placeholder {
        color: $color_7;
      }
      &:-ms-input-placeholder {
        color: $color_7;
      }
      &::-ms-input-placeholder {
        color: $color_7;
      }
    }
    .anticon {
      svg {
        fill: white !important;
      }
    }
    span {
      background-color: $background-color_6 !important;
      border: none !important;
      border-radius: 2px;
    }
    border: none !important;
  }
  .t-filter-block {
    button {
      background: transparent;
      border: 0px;
      padding: 0px;
    }
  }
  .cus-dark-table {
    table {
      width: 100%;
      thead {
        tr {
          th {
            font-size: 12px;
            color: $color_7;
            font-family: $font-family_6;
            font-weight: normal;
            padding: 10px;
            white-space: nowrap;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 12px;
            font-family: $font-family_6;
            color: $color_1 !important;
            padding: 5px 10px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          &:last-child {
            td {
              border-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .status-text {
    font-size: 12px;
    font-family: $font-family_6;
    position: relative;
    display: flex;
    align-items: center;
    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .green-status {
    color: $color_3;
    &::before {
      background-color: $background-color_8;
    }
  }
  .red-status {
    color: $color_4;
    &::before {
      background-color: $background-color_9;
    }
  }
  .action-btn {
    button {
      background: transparent;
      border: 0px;
    }
  }
  .cus-pagination {
    display: flex;
    justify-content: flex-end;
  }
  .page-item.disabled {
    .page-link {
      background: transparent;
      svg {
        path {
          fill: #484848;
        }
      }
    }
  }
  .page-link {
    background-color: $background-color_1;
    border: 0px;
    font-size: 12px;
    color: $color_1;
    font-family: $font-family_6;
    border-radius: 8px;
    &:hover {
      background-color: $background-color_10;
      color: $color_1;
    }
  }
  .pro-menu {
    a {
      font-size: 12px;
      color: $color_1;
      font-family: $font-family_2;
      display: flex;
      align-items: center;
      padding: 15px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      .d-pro-icon {
        margin-right: 10px;
      }
    }
    li {
      &:first-child {
        a {
          padding-top: 0px;
          border-top: 0px;
        }
      }
      &:last-child {
        a {
          padding-bottom: 0px;
          border-bottom: 0px;
        }
      }
    }
    padding: 0px;
    background: var(--darkBlue-200);
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    min-width: 12.5rem;
  }
  .dropdown-item.active {
    background-color: $background-color_1;
    color: $color_1;
  }
  .dropdown-item {
    &:active {
      background-color: $background-color_1;
      color: $color_1;
    }
    &:focus {
      background-color: $background-color_1;
      color: $color_1;
    }
    &:hover {
      background-color: $background-color_1;
      color: $color_1;
    }
  }
  .dark-head-card {
    background: $blueTheme;
    border-radius: 10px;
  }
  .d-c-head {
    padding: 20px;
    border-bottom: 2px solid var(--darkBlue-100);
  }
  .d-c-body {
    padding: 20px;
  }
  .cus-floating-inputs {
    .form-control {
      height: 46px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      background: transparent;
      font-family: $font-family_6;
      font-size: 14px;
      color: $color_1;
      font-size: 12px;
    }
    select.form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
  .cus-floating-inputs.form-floating {
    > label {
      padding: 0.6rem 0.75rem;
      color: $color_7;
      font-family: $font-family_6;
      font-size: 14px;
      padding: 0px;
      transform: scale(1) translateY(0.7rem) translateX(0.95rem);
      background-color: $background-color_7;
      height: auto;
      opacity: 1;
    }
    > .form-control {
      &:focus {
        ~ {
          label {
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            background-color: $background-color_7;
            opacity: 1;
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            opacity: 1;
          }
        }
      }
      &:not(:placeholder-shown) {
        ~ {
          label {
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            background-color: $background-color_7;
            opacity: 1;
            transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
            height: auto;
            opacity: 1;
          }
        }
      }
    }
    > .form-select {
      ~ {
        label {
          transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
          height: auto;
          background-color: $background-color_7;
          opacity: 1;
          transform: scale(0.85) translateY(-0.7rem) translateX(0.95rem);
          height: auto;
          opacity: 1;
        }
      }
    }
  }
  .form-floating {
    > .form-control {
      &:focus {
        padding-top: 0.525rem;
        padding-bottom: 0.625rem;
      }
      &:not(:placeholder-shown) {
        padding-top: 0.525rem;
        padding-bottom: 0.625rem;
      }
    }
  }
  .form-select {
    &:focus {
      box-shadow: none;
    }
  }
  .dark-modal {
    .modal-content {
      background: var(--darkBlue-200);
    }
  }
  .s-ani-img {
    max-width: 100px;
    height: 100px;
    width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .suc-m-block {
    h4 {
      font-size: 18px;
      color: $color_1;
      letter-spacing: 0.05em;
      text-align: center;
    }
    p {
      font-size: 14px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .suc-m-body {
    padding: 50px 30px 30px;
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 15px;
    button {
      background-color: $background-color_1;
      border: 0px;
      padding: 0px;
    }
  }
  .modal-backdrop.show {
    background: linear-gradient(
      116.01deg,
      rgba(47, 50, 53, 0.66) 0.84%,
      rgba(33, 37, 40, 0.66) 16%,
      rgba(0, 0, 0, 0.66) 45.27%
    );
    backdrop-filter: blur(2px);
    opacity: 1;
  }
  .m-head {
    h3 {
      font-size: 18px;
      color: $color_1;
      letter-spacing: 0.05em;
      font-family: $font-family_2;
    }
  }
  .error-msg {
    color: $color_2;
  }
  .opacity04 {
    opacity: 0.4;
  }
  .modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px;
  }
  hr {
    &:not([size]).black-hr {
      height: 2px;
      background: var(--darkBlue-100);
      opacity: 1;
    }
    &:not([size]).black-light-hr {
      height: 2px;
      background: var(--darkBlue-200);
      opacity: 1;
    }
  }
  .cus-up-card {
    border: 1px dashed rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    min-height: 305px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    label {
      position: absolute;
      top: -9px;
      left: 20px;
      background: var(--darkBlue-200);
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .up-con {
    text-align: center;
    h5 {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .b-block {
    position: relative;
    color: $color_6;
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0;
      cursor: pointer;
      z-index: 9;
    }
  }
  .up-name-card {
    border: 1px dashed rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 15px;
    max-width: 250px;
    width: 100%;
    display: flex;
  }
  .f-text {
    flex: 1;
    padding-left: 15px;
    a {
      font-size: 12px;
      color: $color_6;
      font-family: $font-family_6;
      display: block;
    }
    small {
      font-size: 12px;
      color: $color_7;
      font-family: $font-family_6;
    }
  }
  .mo-wrap {
    white-space: nowrap;
  }
  .mr-b8 {
    margin-bottom: 8px;
  }
  .label-title {
    font-size: 12px;
    color: $color_7;
    font-family: $font-family_6;
  }
  .satic-form-group {
    line-height: 0;
  }
  .cus-toggle-switch {
    label {
      margin: 0px;
      cursor: pointer;
      input {
        display: none;
        &:checked {
          ~ {
            span {
              background: #45a717;
              &::after {
                transform: translateY(-50%) translateX(12px);
                transition: all 0.3s linear 0s;
                border: 1px solid rgba(82, 156, 70, 1);
              }
            }
          }
        }
      }
      span {
        width: 28px;
        height: 16px;
        border-radius: 50px;
        background: rgba(255, 255, 255, 0.4);
        display: block;
        position: relative;
        &::after {
          content: "";
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #ffffff;
          left: 0px;
          top: 50%;
          transform: translateY(-50%) translateX(1px);
          transition: all 0.3s linear 0s;
          position: absolute;
          border: 1px solid #ffffff;
        }
      }
    }
  }
  .nav-tabs {
    .nav-link {
      font-size: 18px;
      letter-spacing: 0.05em;
      font-family: $font-family_2;
      position: relative;
      display: block;
      overflow: hidden;
      padding: 10px 0px;
      margin-right: 30px;
      background-color: $background-color_1;
      border: 0px;
      color: $color_7;
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background: #0177fb;
        position: absolute;
        left: 0px;
        bottom: 0px;
        transform: translateY(2px);
        transition: all 0.3s linear 0s;
      }
    }
    .nav-item {
      &:last-child {
        .nav-link {
          margin-right: 0px;
        }
      }
    }
    .nav-item.show {
      .nav-link {
        background-color: $background-color_1;
        border: 0px;
        color: $color_7;
        color: $color_1;
        &::after {
          transform: translateY(-1px);
          transition: all 0.3s linear 0s;
        }
      }
    }
    .nav-link.active {
      background-color: $background-color_1;
      border: 0px;
      color: $color_7;
      color: $color_1;
      &::after {
        transform: translateY(-1px);
        transition: all 0.3s linear 0s;
      }
    }
    border-bottom: 0px;
  }
  .domains-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }
  .domains-c-head {
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    h5 {
      font-size: 12px;
      color: $color_1;
      font-family: $font-family_6;
    }
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }
  .domains-card-body {
    padding: 12px;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
    h4 {
      font-size: 20px;
      color: $color_1;
      font-family: $font-family_2;
      span {
        font-size: 12px;
        color: $color_7;
        font-family: $font-family_6;
        margin-left: 12px;
      }
    }
  }
  .dom-drop-box {
    min-width: 134px;
    background: var(--darkBlue-200);
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 0px;
    li {
      .dropdown-item {
        .da-icon {
          display: flex;
          align-items: center;
          margin-right: 10px;
        }
        font-size: 12px;
        color: $color_1;
        font-family: $font-family_2;
        display: flex;
        align-items: center;
        padding: 15px 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
      padding: 0px 15px;
      &:last-child {
        .dropdown-item {
          border-bottom: 0px;
        }
      }
    }
  }
  .ed-map-block {
    iframe {
      width: 100%;
      height: 234px;
      border-radius: 10px;
    }
  }
  .cus-pro-bar {
    .progress {
      background: #35383b;
      border-radius: 30px;
      height: 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
    .progress-bar {
      background: #45a717;
      border-radius: 50px;
      height: 4px;
    }
    .pro-value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 10px;
        color: $color_1;
        font-family: $font-family_2;
      }
    }
  }
  .a-prog-ani {
    .progress {
      height: 12px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 50px;
    }
    .progress-bar {
      background: rgb(42, 46, 49);
      background: -moz-linear-gradient(
        left,
        rgba(42, 46, 49, 1) 0%,
        rgba(1, 119, 251, 1) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(42, 46, 49, 1) 0%,
        rgba(1, 119, 251, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(42, 46, 49, 1) 0%,
        rgba(1, 119, 251, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--darkBlue-200)', endColorstr='#0177fb', GradientType=1);
      border-radius: 50px;
    }
  }
  .cus-r-btn {
    font-size: 12px;
    color: $color_1;
    font-family: $font-family_2;
    label {
      display: flex;
      align-items: center;
    }
    input {
      display: none;
      &:checked {
        ~ {
          span {
            border: 2px solid #0177fb;
            &::after {
              content: "";
              width: 8px;
              height: 8px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background: #0177fb;
              border-radius: 50%;
            }
          }
        }
      }
    }
    span {
      display: block;
      width: 16px;
      height: 16px;
      border: 2px solid #8c8c8c;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;
    }
  }
  .cus-c-btn {
    font-size: 12px;
    color: $color_1;
    font-family: $font-family_2;
    label {
      display: flex;
      align-items: center;
    }
    input {
      display: none;
      &:checked {
        ~ {
          span {
            border: 2px solid #0177fb;
            background-color: $background-color_2;
            &::after {
              content: "L";
              position: absolute;
              transform: scalex(-1) rotate(-397deg);
              transform-origin: center;
              font-size: 12px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    span {
      display: block;
      width: 16px;
      height: 16px;
      border: 1px solid #8c8c8c;
      position: relative;
      margin-right: 10px;
      border-radius: 2px;
    }
  }
  .ds-board {
    height: calc(100vh - 9.8em);
    display: flex;
    flex-wrap: wrap;
  }
  .ds-search-col {
    max-width: 284px;
    width: 100%;
    position: relative;
  }
  .ds-resuld-col {
    flex: 1;
    border-radius: 4px;
    position: relative;
  }
  .ds-serach-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 10px;
    input {
      background: transparent;
      border: 0px;
      font-size: 12px;
      font-family: $font-family_6;
      box-sizing: border-box;
      color: $color_1;
      flex: 1;
      padding-right: 61px;
      width: 100%;
      &::placeholder {
        color: $color_7;
      }
      &:-ms-input-placeholder {
        color: $color_7;
      }
      &::-ms-input-placeholder {
        color: $color_7;
      }
    }
    button {
      background-color: $background-color_1;
      border: 0px;
      padding: 0px 15px;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      line-height: 0;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  .ds-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .list-block {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.4);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $background-color_7;
      border: 1px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }
    ul {
      padding: 0px;
      margin: 0px;
      li {
        list-style-type: none;
        font-size: 12px;
        color: $color_1;
        font-family: $font-family_6;
        padding: 8px 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        &:hover {
          background: rgba(255, 255, 255, 0.05);
          cursor: pointer;
        }
      }
      li.list-active {
        background: rgba(255, 255, 255, 0.05);
        cursor: pointer;
      }
    }
  }
  .ds-r-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ds-r-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .b-card-row {
    flex: 1;
    overflow: auto;
  }
  .ds-r-head {
    h3 {
      font-size: 16px;
      color: $color_1;
      font-family: $font-family_2;
      margin-bottom: 15px;
    }
    .nav-tabs {
      .nav-link {
        font-size: 14px;
      }
    }
  }
  .info-black-card {
    background: #151a2c;
    padding: 20px;
  }
  .info-gray-card {
    background: var(--darkBlue-200);
    padding: 20px;
  }
  .mr-b15 {
    margin-bottom: 15px;
  }
  .over-wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: $blueTheme;
    border-radius: 4px;
  }
  .cus-scroll {
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.4);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $background-color_7;
      border: 1px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }
  }
  .ds-foot {
    padding: 30px 0px 0px;
  }
  .m-d-list {
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        margin-bottom: 10px;
      }
    }
  }
  .tls-msg-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
  }
  .policies-block {
    .tls-msg-card {
      background-color: $background-color_1;
    }
  }
  .n-list-wrap {
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        padding: 12px 20px;
        font-family: $font-family_2;
        font-size: 12px;
        color: $color_1;
        margin-bottom: 10px;
      }
    }
  }
  .gray-box {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
  }
  .black-card {
    background: var(--darkBlue-100);
  }
  .val-pro-box {
    max-width: 191px;
    width: 100%;
    .red-bar {
      background: #dc3545;
    }
  }
  .price-card {
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }
  .price-c-head {
    padding: 25px;
  }
  .price-card-body {
    padding: 25px;
  }
  .price-list {
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        font-size: 12px;
        color: $color_1;
        font-family: $font-family_6;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .green-tick {
    &::before {
      content: "";
      width: 100%;
      max-width: 24px;
      flex: 0 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .cross-tick {
    &::before {
      content: "";
      width: 100%;
      max-width: 24px;
      flex: 0 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("../images/cross.png") no-repeat center;
    }
  }
  .blue-c-head {
    background: #0177fb;
    border: 1px solid #0177fb;
    border-radius: 4px;
    position: relative;
  }
  .batch-note {
    background: #d8eaff;
    border-radius: 2px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    min-width: 112px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: 30px;
  }
  .pay-m-block {
    padding: 15px;
    background: var(--darkBlue-200);
    max-width: 279px;
    width: 100%;
    margin-top: 15px;
    ul {
      padding: 0px;
      margin: 0px;
      li {
        list-style-type: none;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        padding: 15px;
      }
    }
  }

  .t-strip {
    background: #d8eaff;
    padding: 10px;
    margin: 10px -10px;
  }
  .order-block {
    margin-top: 15px;
  }
  .breack-list {
    ul {
      padding: 0px;
      margin: 0px;
      max-width: 263px;
      width: 100%;
      li {
        background: #212528;
        padding: 10px;
        list-style-type: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        h5 {
          font-size: 12px;
          color: $color_1;
          font-family: $font-family_6;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &:last-child {
          background: #d8eaff;
          h5 {
            font-family: $font-family_2;
            color: $color_6;
          }
        }
      }
    }
  }
  .chart-block {
    img {
      width: 100%;
      height: auto;
    }
  }
  .h-80 {
    max-height: 400px;
    overflow-x: auto;
  }
  .page-header {
    font-family: $font-family_8;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: $color_1;
  }
  .add-btn {
    background: #0177fb;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .search {
    width: 200px;
  }
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: -1px 5px;
  }
  .bg-green {
    background-color: $background-color_11;
  }
  .bg-red {
    background-color: $background-color_12;
  }
  .swal2-popup {
    // background: var(--darkBlue-100) !important;
    color: $color_1 !important;
    box-shadow: 0px 113px 45px rgba(0, 0, 0, 0.01),
      0px 64px 38px rgba(0, 0, 0, 0.05), 0px 28px 28px rgba(0, 0, 0, 0.09),
      0px 7px 16px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  .icon {
    color: $color_1;
    font-size: 20px;
  }
  .ant-pagination-item {
    color: $color_1;
  }
  .ant-pagination-item-link {
    color: $color_1 !important;
  }
  .ant-pagination-item-ellipsis {
    color: $color_1 !important;
  }
  .ant-pagination {
    .ant-pagination-item-active {
      background-color: $background-color_13;
      border-color: $border-color_1;
    }
  }
  .ant-select-selector {
    background-color: $background-color_13 !important;
    border-color: $border-color_1 !important;
    color: $color_1 !important;
    border: 1px solid #d4b8b8af !important;
    border-color: $border-color_2 !important;
    border-radius: 4px !important;
    height: 43px !important;
    padding: 6px 8px !important;
    overflow: hidden !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .input-affix-wrapper {
    background-color: $background-color_14 !important;
    input {
      background-color: $background-color_14 !important;
      color: $color_1 !important;
    }
  }
  .dark-card {
    width: 100%;
    height: 100%;
    background: #2a2e31;
    padding: 10px;
  }
  .bulk-btn {
    width: 105px;
    height: 32px;
    background: #d8eaff;
    border-radius: 4px;
    font-family: $font-family_8;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $color_6;
    padding: 6px 10px;
  }
  .ant-empty-description {
    color: $color_1 !important;
  }
  .cursor {
    cursor: pointer;
  }
  .MuiOutlinedInput-root {
    color: $color_1 !important;
  }
  .ant-table {
    background-color: $background-color_1 !important;
    background-color: $background-color_18 !important;
    td {
      padding: 1px !important;
      border-bottom: 1px solid var(--white-20, rgba(255, 255, 255, 0.2)) !important;
      color: $color_15 !important;
      font-size: 12px;
      font-family: $font-family_6 !important;
    }
    th {
      color: $color_17 !important;
      font-size: 12px;
      font-family: $font-family_6;
      font-weight: 200 !important;
    }
  }
  .ant-table-tbody {
    > tr.ant-table-row {
      &:hover {
        > td {
          background-color: $background-color_15 !important;
        }
      }
    }
    > tr {
      > td.ant-table-cell-row-hover {
        background-color: $background-color_15 !important;
      }
    }
  }
  .ant-select {
    width: 100%;
  }
  .ant-select-dropdown {
    border: 1px solid #bdbdbd !important;
    border-top: none !important;
    border-radius: 0 0 4px 4px !important;
    margin-top: 0 !important;
    box-shadow: none !important;
    background: #2a2e31;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 4px;
    background-color: $background-color_6;
  }
  .ant-select-dropdown-menu-item {
    color: $color_11 !important;
  }
  .ant-select-selection-item-content {
    color: $color_12;
    font-size: 10px;
    color: $color_1;
    font-family: $font-family_2;
    background: rgba(255, 255, 255, 0) !important;
  }
  .anticon-close {
    svg {
      fill: #fff !important;
      color: $color_12;
    }
  }
  .ant-select-selection-placeholder {
    color: $color_7;
    font-size: 14px;
    font-family: $font-family_6;
    color: $color_7 !important;
  }
  .ant-table-cell {
    &::before {
      display: none !important;
    }
    padding: 0px 4px !important;

    background: var(--lightBlue) !important;
    border: none !important;
  }
  .ant-table-thead {
    > tr {
      > th {
        padding: 10px 4px !important;
      }
    }
    &::before {
      background-color: $background-color_18 !important;
    }
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: #ffffff;
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 12px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: $border-color_2 !important;
    border: 1px solid;
  }
  label[data-shrink="false"] {
    + {
      .MuiInputBase-formControl {
        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
          height: 10px !important;
        }
      }
    }
  }
  .error {
    span {
      color: $color_13;
      font-family: $font-family_9;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      text-align: left;
      margin-top: 3px;
      margin-right: 14px;
      margin-bottom: 0;
      margin-left: 14px;
    }
  }
  &:where(.css-dev-only-do-not-override-diro6f).ant-menu-light {
    font-size: 14px;
    color: $color_9;
    font-family: $font-family_6;
    display: flex;
    align-items: center;
    background: #2a2e31 !important;
  }
  .grey-border {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .text-grey {
    span {
      font-size: 12px;
      font-family: $font-family_6;
      color: $color_1 !important;
      padding: 5px 10px;
    }
  }
  .bg-black {
    background-color: $background-color_6;
    padding: 10px;
  }
  .bg-grey {
    background: #35383b !important;
  }
  td {
    color: $color_12;
  }
  th {
    color: $color_12;
  }
  &:where(.css-dev-only-do-not-override-diro6f).ant-select-multiple {
    .ant-select-selection-placeholder {
      font-size: 14px;
      font-family: $font-family_6;
      color: $color_7 !important;
    }
    .ant-select-selection-item {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50px;
      padding: 0px 8px;
      border: 0px;
    }
    .ant-select-selection-item-remove {
      line-height: 17px;
    }
  }
  &:where(.css-dev-only-do-not-override-diro6f).ant-select-dropdown {
    .ant-select-item {
      font-size: 12px;
      font-family: $font-family_2;
      color: $color_1;
      margin: 2px 0px;
    }
    .ant-select-item-option-selected {
      &:not(.ant-select-item-option-disabled) {
        font-weight: normal;
      }
    }
  }
  .ant-select-selection-item {
    background: rgba(255, 255, 255, 0.2) !important;
  }
  .ant-select-item-option-selected {
    background: rgba(255, 255, 255, 0.2) !important;
  }
  .ant-menu {
    .ant-menu-root {
      background: "#2a2e31" !important;
    }
  }
  .active {
    color: $color_6 !important;
  }
  .submit-Button {
    background-color: $background-color_2 !important;
  }
  .custom-cancel-button {
    background-color: $background-color_3 !important;
    color: $color_6 !important;
  }
  .switch-label {
    font-family: $font-family_6;
    font-weight: 400;
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
    color: $color_7;
  }
  .span-title {
    font-family: $font-family_6;
    font-weight: 700;
    font-size: 14px;
    color: $color_1;
  }
  .dark-mode-editor {
    .jodit_container {
      background-color: $background-color_16;
      color: $color_12;
    }
    .jodit_toolbar {
      .jodit_toolbar_btn {
        color: $color_12;
      }
    }
  }
  .jodit_theme_dark {
    .jodit-toolbar__box {
      &:not(:empty) {
        background: #2a2e31;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
      }
    }
    .jodit-wysiwyg {
      background: #2a2e31;
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
    }
    .jodit-workplace {
      + {
        .jodit-status-bar {
          &:not(:empty) {
            background-color: $background-color_14;
            border-color: $border-color_3;
            color: $color_14;
            border: 1px solid rgba(255, 255, 255, 0.1) !important;
          }
        }
      }
    }
  }
  .jodit-container {
    &:not(.jodit_inline) {
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
    }
  }
  .notification {
    background: #2d2f42;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 20px;
    margin-top: 10px;
    // border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: $color_1;
    }
  }
  .text-link {
    font-family: $font-family_6;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    cursor: pointer;
    color: $color_6;
  }
  .heading-label {
    font-family: $font-family_6;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color_7;
  }
  .heading-value {
    font-family: $font-family_6;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: $color_1;
  }
  .outline-effect {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 2px;
  }
  .outline-label {
    background: #2d2f42 !important;

    color: #ffffff !important;
  }
  .d-c-head-black {
    background: #2d2f42;
    margin: 10px;
    padding: 10px;
  }
  .heading {
    color: $color_15;
    font-size: 12px;
    font-family: $font-family_6;
    line-height: 18px;
    margin-bottom: 20px;
  }
  .nest-table {
    .ant-table-cell {
      padding: 0px 4px !important;

      background-color: $background-color_14;
    }
    th {
      color: $color_16 !important;
      font-size: 12px;
      font-family: $font-family_6;
      font-weight: 400 !important;
      padding-left: 10px;
    }
    &.ant-table-wrapper {
      .ant-table-container {
        table {
          > thead {
            > tr {
              &:first-child {
                > * {
                  &:first-child {
                    border-start-start-radius: 0px;
                  }
                  &:last-child {
                    border-start-end-radius: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
    td {
      color: $color_1 !important;
      font-size: 12px;
      font-family: $font-family_6;
      border-bottom: 1px solid #ffffff66 !important;
    }
  }
  .tab-label {
    color: $color_7;
    font-size: 14px;
    font-family: $font-family_6;
    font-weight: 700;
    letter-spacing: 0.7px;
    padding: 10px;
    cursor: pointer;
  }
  .tab-active {
    color: $color_12;
    border-bottom: 2px solid #0177fb;
    cursor: pointer;
  }
  .note {
    color: $color_17;
    font-size: 12px;
    font-family: $font-family_6;
    font-weight: 500;
  }
  .fw-800 {
    font-weight: 800;
  }
  .ant-modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    padding-top: 10px;
  }
  .swal2-html-container {
    color: $color_7;
    text-align: center;
    font-size: 14px;
    font-family: $font-family_6;
    letter-spacing: 0.7px;
  }
  .swal2-title {
    color: $color_12;
    font-size: 18px;
    font-family: $font-family_6;
    font-weight: 700;
    letter-spacing: 0.9px;
  }
  .swal2-confirm {
    background: #0177fb !important;
    color: $color_12;
    text-align: center;
    font-size: 14px;
    font-family: $font-family_6;
    font-weight: 500;
  }
  .high {
    color: $color_4;
  }
  .label-white {
    color: $color_15;
    font-size: 12px;
    font-family: $font-family_6;
    display: block;
  }
  .label-gray {
    color: $color_17;
    font-size: 12px;
    font-family: $font-family_6;
    display: block;
  }
  .f-700 {
    font-weight: 700;
  }
  .c-green {
    color: $color_18;
  }
  .nested-colaps {
    background-color: #151a2c;
  }
  .addEditTestLabel {
    color: rgba(255, 255, 255, 0.4);
  }
  .addEditTestDesc {
    color: #ffffff;
  }
  .colorLabel {
    color: rgba(255, 255, 255, 0.4);
  }
  .span-gray {
    span {
      color: $color_17;
      font-size: 12px;
      font-family: $font-family_6;
    }
  }
  .b-black {
    background-color: $background-color_6;
  }
  .dropdown-menu {
    background: #2a2e31;
  }
  .border-bottom-1 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .dropdown-divider {
    margin: 5px;
  }
  .feature {
    background-color: $background-color_17;
    border-bottom: 1px solid;
  }
  .c-sky {
    background-color: $background-color_3;
  }
  .text-blue {
    color: $color_6;
    font-size: 12px;
    font-family: $font-family_6;
    font-style: normal;
    font-weight: 700;
  }
  .border-bottom-gray {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .plan-card {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
    padding: 20px;
  }
  .fs-18 {
    font-size: 18px;
    font-family: $font-family_6;
  }
  .fs-32 {
    font-size: 32px;
    font-family: $font-family_6;
  }
  .fs-12 {
    font-size: 12px;
    font-family: $font-family_6;
  }
  .f-gray {
    color: $color_17;
    font-size: 12px;
    font-family: $font-family_6;
  }
  .ant-modal-content {
    background-color: $blueTheme !important;
  }
  .ant-modal-header {
    background-color: $blueTheme !important;
  }
  .ant-modal-body {
    background-color: $blueTheme !important;
  }
  .ant-table-wrapper {
    background-color: $background-color_18 !important;
  }
  .ant-table-container {
    border-radius: 0px !important;
  }

  .scan-table {
    background: #151a2c !important;
  }
  &.ant-table-wrapper {
    .ant-table-container {
      table {
        > thead {
          > tr {
            &:first-child {
              > * {
                &:first-child {
                  border-start-start-radius: 0px !important;
                }
                &:last-child {
                  border-start-end-radius: 0px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .dashboard-graph {
    background-color: $blueTheme;
  }
  .dashboard-graph1 {
    background-color: $blueTheme;
  }
  .MuiPaper-root {
    background-color: $background-color_6;
  }
  .rc-virtual-list-holder-inner {
    background-color: $background-color_6;
  }

  @media (max-width: 768px) {
    .login-wrap {
      padding: 10px;
    }
    .l-inn-content {
      padding: 20px;
    }
    .login-box {
      &::after {
        display: none;
      }
      h3 {
        font-size: 18px;
      }
      h5 {
        font-size: 13px;
      }
    }
    .l-btm-btn {
      text-align: center;
      button {
        height: 40px;
        font-size: 11px;
        padding: 0px 20px;
      }
    }

    .f-r-block {
      a {
        font-size: 12px;
      }
      .r-cus-check {
        font-size: 12px;
      }
    }
    .l-s-text {
      font-size: 12px;
    }
    .l-b-text {
      font-size: 10px;
    }
  }

  .graph-title {
    color: var(--white-100, #fff);
  }
  .swal2-modal {
    background-color: $blueTheme !important;
  }

  .ant-popover-inner {
    background-color: $blueTheme !important;
  }
  .inherit-nest .ant-table-cell {
    background-color: var(--darkblue-200) !important;
    padding: 0px 4px !important;
  }

  .inherit-nest .ant-table-container {
    background-color: var(--darkblue-200) !important;
  }

  .inherit-nest .ant-spin-container {
    background-color: var(--darkblue-200) !important;
  }
  .ant-empty-image svg {
    fill: #ffffff;
  }

  .ant-table-column-sorter-inner {
    color: rgb(110, 113, 124);
    // margin-right: 10px;
  }

  .threat-hunting-legend {
    color: rgba(255, 255, 255, 0.4);
    // font-family: Inter-Regular;
    // font-size: 14px;
    // font-weight: 100;
  }
  .threat-hunting-fieldset {
    border-color: rgba(255, 255, 255, 0.1);
  }
  @media (min-width: 576px) {
    .modal-dialog.dialog450 {
      max-width: 450px;
      margin: 1.75rem auto;
    }
  }
}

:where(.css-dev-only-do-not-override-1o6jxzt).ant-dropdown
  .ant-table-filter-dropdown
  .ant-table-filter-dropdown-tree
  .ant-tree-treenode-checkbox-checked
  .ant-tree-node-content-wrapper,
:where(.css-dev-only-do-not-override-1o6jxzt).ant-dropdown
  .ant-table-filter-dropdown
  .ant-table-filter-dropdown-tree
  .ant-tree-treenode-checkbox-checked
  .ant-tree-node-content-wrapper:hover {
  background-color: none;
}

.ant-table-column-sorter-inner {
  margin-right: 10px;
}

@media screen and (max-width: 1000px) {
  .list-device {
    display: block;
  }
  .white-theme .list-device {
    color: black;
  }
  .font-size14 {
    font-size: 10px;
  }
  .hide-device .ds-search-col {
    display: none;
  }

  .show-device .ds-search-col {
    display: block;
    max-width: 100%;
  }

  .hide-device .ds-resuld-col {
    display: block;
  }

  .show-device .ds-resuld-col {
    display: none;
  }
  .sub-card {
    width: 50%;
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .hide-sidebar .sidebar-wrap {
    display: none !important;
  }
  .show-sidebar .sidebar-wrap {
    display: block !important;
  }
  .body-wrap {
    padding-left: 0px !important;
    // padding-top: 80px;
  }
  .sidebar-icon {
    display: block;
    position: absolute;
    left: 10px;
    top: 20px;
  }
  .sidebar-icon1 {
    display: block;
    position: absolute;
    left: 180px;
    top: 20px;
    color: #ffffff;
  }

  .dark-theme .body-r-container {
    padding: 10px 5px 20px;
  }
  .white-theme .body-r-container {
    padding: 10px 5px 20px;
  }
  .blue-theme .body-r-container {
    padding: 10px 5px 20px;
  }
  .sub-card {
    width: 100%;
    // margin-top: 10px;
  }
}

@media screen and (max-width: 568px) {
  .pay-m-block {
    max-width: 100%;
  }
  .order-card {
    max-width: 100%;
  }
}
.MuiSelect-nativeInput {
  display: none !important;
}

.scan-table .ant-table-cell {
  height: 42px;
  padding: 0px 4px !important;
}
.scan-table th {
  font-size: 14px !important;
}
.scan-table .ant-table-tbody td {
  font-size: 14px !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.ant-table-filter-trigger .anticon-filter svg {
  color: rgb(110, 113, 124);
  margin-right: 10px;
}
.ant-table-filter-column .active .anticon-filter svg {
  color: #0177fb;
  margin-right: 10px;
}
.font-weight-400 {
  font-weight: 400;
}
.underline {
  text-decoration: underline !important;
}
.ant-table-filter-column {
  display: inline-flex !important;
}
.ant-table-column-sorters {
  display: inline-flex !important;
}

.endpointBottom {
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.last_modified {
  color: #01b8aa;

  font-family: Inter-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  // line-height: 18px; /* 150% */
}

.pd-l40 {
  padding-left: 40px !important;
}

.custom-column-padding {
  padding-left: 10px !important;
}
.active .snav-icon svg {
  fill: #1677ff;
}

.radio[disabled] {
  background-color: #ffffff !important;
}

.ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
  background-color: #0c64de;
}

.footer1 {
  position: fixed;
  width: 100%;
  bottom: 10px;
  // display: flex;
  justify-content: center;
  align-items: center;
  // padding-left: 5px;
  border-radius: 3px;
  height: 32px;
  margin-top: 45px;
  color: #a5b3cd;

  font-family: Inter-Regular;
  font-size: 14px;
  font-weight: 400;
  z-index: 999;
  margin-left: 20px;
  margin-right: 20px;
  background-color: none;
}

.swal2-popup {
  // background-color: #212121;
}

.swal2-html-container {
  color: white;
}

.swal2-title {
  color: white;
}

.mui-label-custom {
  margin: 0px 0px -6px 0px !important;
}

.outline-label {
  margin-top: -23px;
  width: 83px;
  text-align: center;
  font-size: 11px !important;
  font-family: Inter-Regular !important;
}

.custom-table {
  .ant-table-cell {
    height: 40px !important;
    padding: 0px 4px !important;
  }
}

.h-80 {
  min-height: 200px;
  max-height: 80vh;
  height: 100%;
  overflow: auto;
}

.border-bottom-2 {
  border-top: 2px solid #212528;
}

.scan-table .ant-table-cell {
  padding: 0px 4px !important;
}

.MuiBox-root {
  padding: 0px !important;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #1462a0;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  background-color: #1462a0 !important;
}

.ant-input[disabled] {
  color: rgba(255, 255, 255, 0.4);
}
.ant-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
  border-radius: 4px;
}

.highcharts-background {
  background-color: #0177fb;
}

.highcharts-credits {
  display: none;
}
.swal2-popup {
  background: #1f1f1f !important;
}

.swal2-title {
  color: white !important;
}
.custom-margin-50 {
  display: grid;
}

.dashboard-graph {
  margin-top: 10px;
}
.center {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.custom-tooltip span {
  display: block;
}

.ant-tooltip-content {
  width: 400px;
}

.tooltip-gray {
  font-size: 12px;
  // color: rgba(255, 255, 255, 0.4);
  font-family: Inter-Regular;
  font-weight: 600;
}

.text-end1 {
  text-align: end;
}
.livechart {
  background: #181b1f;
  height: 280px;
  font-size: 30px;
}

.text1 {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-top: 1px;
}

.chart-details {
  color: rgb(110, 159, 255);
  margin-bottom: 5px !important;
  padding-bottom: 10px;
}

.chart-value {
  margin-left: 10px;
  margin-top: -4px;
}

.ruleset-second-par {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1%;
}
.container-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ruleset-add-btn {
  cursor: pointer;
  font-size: 3rem;
}
.ruleset-second-row {
  border: 1px solid #404346;
  border-radius: 2%;
}
.ruleset-icons {
  color: white !important;
}
.ruleset-icons > span {
  transition: transform 0.3s ease-in-out;
}

.ruleset-icons > span:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.ruleset-add label,
.ruleset-add select,
.ruleset-add .MuiSelect-select {
  font-size: 85%;
}

.ruleset-add .error {
  display: none;
}
.ruleset-checkboxes {
  font-size: 75%;
  padding: 0.5rem;
}
.ruleset-checkboxes .m-2 {
  margin: 0 !important;
}
.add-content-match {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.ruleset-proto {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.ruleset input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card-text-white {
  padding: 10px;
}

.card-text-white a {
  // border-top: 1px solid white;
  // width: 100%;
  font-size: 10px;
}

@media (min-width: 1200px) {
  .resposive-card {
    width: 14.1%;
  }
  .count-card {
    width: 24.74%;
  }
}
@media (max-width: 1100px) {
  .count-card {
    width: 50%;
  }
}
@media (max-width: 1000px) {
  .resposive-card {
    width: 25%;
  }
  .count-card {
    width: 50%;
  }
}
@media (max-width: 800px) {
  .resposive-card {
    width: 33.33%;
  }
}
@media (max-width: 600px) {
  .resposive-card {
    width: 50%;
  }
}
@media (max-width: 400px) {
  .resposive-card {
    width: 100%;
  }
}

.theme-box {
  border: 1px solid #959798;
  border-radius: 5px;
  width: 150px;
  height: 150px;
  margin: 5px;
  cursor: pointer;
  /* padding: 0px; */
}

.theme-box img {
  object-fit: contain;
  width: 100%;
  height: 100px;
  /* padding: 0px; */
}

.theme-box:hover {
  border: 1px solid #0177fb;
  transform: scale(1.05);
  transition: 1ms ease-in;
}

.activeBorder {
  border: 1px solid #0177fb;
  color: #0177fb;
  font-family: Inter-Regular;
  transform: scale(1.05);
  transition: 1ms ease-in;
}

.theme-box:hover .fs-12 {
  color: #0177fb;
  font-family: Inter-Regular;
}

.card-icon svg path {
  fill: #0177fb !important;
}

.custom-table-1 {
  .ant-table-content table {
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  .ant-table-content td {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    // color: #acacac !important;
    font-weight: 900 !important;
    font-size: 12px !important;
  }
  .ant-table-content th {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;

    font-weight: 600 !important;
    font-size: 12px;
    color: white !important;
  }
  .ant-table-cell {
    padding: 5px !important;
    height: 40px;
  }

  tr:nth-child(odd) td {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
  .ant-table-thead .ant-table-row-expand-icon-cell {
    background: none !important;
  }
  // tr:hover {
  //   scale: 1.01;
  // }
}
.custom-table-2 {
  .ant-table-content table {
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  .ant-table-content td {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    // color: #acacac !important;
    font-weight: 900 !important;
    font-size: 12px !important;
  }
  .ant-table-content th {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;

    font-weight: 600 !important;
    font-size: 12px;
    color: white !important;
  }
  .ant-table-cell {
    padding: 5px !important;
    height: 40px;
  }

  tr:nth-child(odd) td {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
  .ant-table-thead .ant-table-row-expand-icon-cell {
    background: #212529 !important;
  }
  // tr:hover {
  //   scale: 1.01;
  // }
}

.label-white {
  color: #c9c9c9;
  font-size: 12px;
  font-family: Inter-Regular;
  display: block;
  font-weight: 600;
}
.ant-tooltip-inner {
  background: #212528 !important;
}

.react-flow__node {
  box-shadow: none !important;
  border: none !important;
}

.isDisabled {
  // color: currentColor;
  cursor: not-allowed;
}

.new-chat-btn {
  text-decoration: none;
  color: #ffffff;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid;
  border-color: hsla(0, 0%, 100%, 0.2);
  text-align: center;
  font-size: 14px;
  padding: 8px;
  background-color: #1677ff;
}
.background-none {
  background: none !important;
}
.new-chat-btn:hover {
  cursor: pointer;
  color: #ffffff;
  background: none;
  // background: hsla(0, 0%, 100%, 0.2);;
}
.background-none:hover {
  background-color: #1677ff !important;
}
.old-chat {
  display: block;
  font-size: 14px;
  // color: #fff;
  padding: 10px;
  text-decoration: none;
}
.old-chat:hover {
  background: hsla(0, 0%, 100%, 0.2);
  cursor: pointer;
  color: #fff;
}
.selected-chat {
  background: hsla(0, 0%, 100%, 0.2);
  cursor: pointer;
  color: #fff;
}

.day-title {
  font-size: 12px;
  font-weight: 600;
}

.MuiPaper-root {
  // box-shadow:none!important
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 1px !important;
}
.chat-height {
  height: 75vh;
  overflow: auto;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px gray inset !important;
}

.height-100 {
  height: 100%;
}

.upload-file {
  height: 84px;
  width: 300px;
  border: 1px solid gray;
  border-radius: 10px;
  background-color: #2f2f2f;
  padding: 10px;
}

.upload-file .close-btn {
  position: relative;
  right: -39px;
  top: -23px;
}

.r-col-12 {
  width: 99%;
}

.upload-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #2c2c2c;
  padding: 1.5rem;
  border-radius: 8px;
  width: 300px;
  margin: 0 auto;
  color: #fff;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.required {
  color: #e63946;
  margin-left: 0.2rem;
}

.file-input {
  // background-color: #444;
  color: #ccc;
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #444;
}

.file-input:hover {
  background-color: #555;
}

.preview-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.image-preview {
  max-width: 100%;
  max-height: 150px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.upload-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.7rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #0056b3;
}

/* Container for the app */
.container {
  height: 600px;
  width: 100%;
}

/* Background image wrapper */
.background {
  position: relative;
  width: 1000px;
  height: 700px;

  border-radius: 8px;
}

/* Draggable dots */
.dot1 {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  border: 2px solid white;
}

.cloud-endpoint img:hover {
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  scale: 1.1;
  cursor: pointer;
}
