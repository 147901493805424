.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  text-align: center;
  color: white;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  margin-bottom: 20px;
}

.input {
  margin-top: 2px;
}

.talk {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.btn-gradient-border {
  background: none;
  border: 2px solid transparent;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.btn-gradient-border:hover {
  border-color: white;
}
.btn-gradient-border {
  color: white;
  border: 2px double transparent;
  background-image: linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-radius: 30px;
}

.mt-3 {
  margin-top: 1rem;
}

.heading-cylo {
  font-size: 72px;
  background: #1677ff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
.subline-text {
  font-size: 16px;
  color: #fff;
}

.overlay .content {
  font-size: 18px;
}
.overlay .content1 {
  font-size: 18px;
  color: #1677ff;
}
.loader {
  background-color: linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  color: white;
}
/* src/FloatingButton.css */
/* src/FloatingButton.css */

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #1677ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  overflow: hidden;
  z-index: 9999;
}
.wave1 {
  animation: ripple 2s infinite;
}

.floating-button:hover {
  animation: bounce 0.3s infinite;
}

.floating-button:active {
  background-color: #3700b3;
  animation: ripple 0.6s linear;
}

.microphone-icon {
  font-size: 24px;
  color: white;
  transition: transform 0.3s ease;
}

.listening {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}
