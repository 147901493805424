.sidebar {
  width: 300px;
  /* background-color: #ffffff; */
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.sidebar h2 {
  margin: 0 0 20px 0;
  color: #007bff;
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.sidebar nav ul li {
  margin: 10px 0;
}

.sidebar nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.quick-links {
  flex-grow: 1;
}

.quick-links h3 {
  margin-bottom: 10px;
  color: #007bff;
}

.quick-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.quick-links ul li {
  margin: 5px 0;
}

.quick-links ul li a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.new-chat-button {
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.new-chat-button:hover {
  background-color: #0056b3;
}

/* src/Dropdown.css */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}

.dropdown-toggle:hover {
  background-color: #2980b9;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #2a2e31;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  margin-top: 5px;
  right: 0; /* Position the menu to the right edge of the button */
  transform: translateX(-0%); /* Move the menu to the left side of the button */
  color: white !important;
}

.dropdown-item {
  padding: 6px 16px;
  cursor: pointer;
  color: white;
}

.dropdown-item:hover {
  background-color: #0a58ca;
  color: white;
}

// .ant-input {
//   border: none !important;
// }
.ant-input:focus {
  border: none !important;
  outline: none !important;
}
.chatbox :nth-child(2) {
  display: flex;
  align-items: end;
  color: white;
}

.chatbox :nth-child(3) {
  display: flex;
  align-items: end;
  color: white;
}
.chatbox :nth-child(4) {
  display: flex;
  align-items: end;
  color: white;
}
.chatbox :nth-child(4) {
  display: flex;
  align-items: end;
  color: white;
}

.bottom-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  padding: 10px 20px;
  padding-left: 300px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.sender {
  right: 10px;
  /* width: 400px; */
  /* width: 100%; */
  max-width: 90%;
  background-color: #2f2f2f;
  margin-top: 10px;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}
.sender1 {
  display: flex;

  justify-content: end;
  border-radius: 10px;
}

.chatbox {
  background-color: #2f2f2f;
  border-radius: 10px;
  margin-top: 10px;
}
.chatbox textarea {
  width: 100%;
  background-color: #2f2f2f;
  color: white;
  border: none;
}

.chatbox button {
  height: 30px;
  width: 30px;
  /* display: flex; */
  border-radius: 9999px;
  background-color: #2f2f2f;
}
.w-96 {
  width: 98%;
}

.chatbox textarea:focus-visible {
  outline: none; /* Change 'blue' to any color you prefer */
}

.gray-100 {
  background-color: #676767 !important;
}
.w-80 {
  height: 74vh;
  overflow: auto;
}
.focused {
  border: 2px solid white !important;
}

.ant-input:focus {
  outline: none !important;
}
.ant-input:focus {
  box-shadow: none !important;
}

.sender-3 {
  padding: 30px 0px;
}

.edit-sender {
  display: flex;
  justify-content: end;
  flex-direction: column;
  padding-left: 20vw;
}

.typing-effect {
  display: inline-block;
  font-family: monospace;
  white-space: pre;
}

.cursor1 {
  display: inline-block;
  background-color: black;
  width: 2px;
  animation: blink 0.7s steps(1) infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.chat-card {
  //   width: 190px;
  //   height: 254px;
  margin: 0 auto;
  background-color: #2a2e31;
  border-radius: 8px;
  z-index: 1;
  color: red;
}

.tools {
  display: flex;
  align-items: center;
  padding: 9px;
}

.circle {
  padding: 0 4px;
}

.box {
  display: inline-block;
  align-items: center;
  width: 10px;
  height: 10px;
  padding: 1px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.chat-card:hover {
  box-shadow: 0 0 20px rgba(225, 219, 219, 0.8);
}

.card__content {
  color: white;
  padding: 20px 4px;
}

.white-theme {
  .chat-card {
    // background: #2a3f54
    background: #ffffff;
  }
  .chatbox {
    // background-color: #2a3f54;
    background: #ffffff;
    border: 1px solid #c6c7c8 !important;
  }
  .chatbox textarea {
    background: #fff;
    color: #000 !important;
  }
  .card__content {
    color: #000;
  }
  .old-chat {
    color: black !important;
  }
  .sender {
    background: #fff !important;
    box-shadow: 0 4px 8px rgba(173, 216, 230, 0.6);
  }
}

.dark-theme {
  .chat-card {
    background: #2a2e31;
  }
  .chatbox {
    background-color: #2f2f2f;
  }
  .chatbox textarea {
    background: #2f2f2f;
  }
  .old-chat {
    color: white !important;
  }
}

.blue-theme {
  .chat-card {
    background: #2d2f42;
  }

  .chatbox {
    background-color: #2d2f42;
  }
  .chatbox textarea {
    background: #2d2f42;
  }
  .old-chat {
    color: white !important;
  }
}
.disa label {
  color: #959798 !important;
}
