@font-face {
    font-family: Inter-Black;
    src: url('../fonts/Inter-Black.ttf');
}

@font-face {
    font-family: Inter-Bold;
    src: url('../fonts/Inter-Bold.ttf');
}

@font-face {
    font-family: Inter-ExtraBold;
    src: url('../fonts/Inter-ExtraBold.ttf');
}

@font-face {
    font-family: Inter-ExtraLight;
    src: url('../fonts/Inter-ExtraLight.ttf');
}

@font-face {
    font-family: Inter-Light;
    src: url('../fonts/Inter-Light.ttf');
}

@font-face {
    font-family: Inter-Medium;
    src: url('../fonts/Inter-Medium.ttf');
}

@font-face {
    font-family: Inter-Regular;
    src: url('../fonts/Inter-Regular.ttf');
}
 

@font-face {
    font-family: Inter-Thin;
    src: url('../fonts/Inter-Thin.ttf');
}