/* For WebKit browsers (Chrome, Safari) */
.ant-table-wrapper ::-webkit-scrollbar {
  width: 6px; /* Adjust the width of the scrollbar */
  height: 6px; /* Adjust the height of the scrollbar */
}

.ant-table-wrapper ::-webkit-scrollbar-thumb {
  // background-color: #4caf50; /* Change to your desired color */
  border-radius: 10px; /* Adjust the roundness of the scrollbar */
  border: 1px solid #ffffff; /* Change the color of the scrollbar thumb border */
}

.ant-table-wrapper ::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change to your desired color for the track */
  border-radius: 10px;
}

/* For Firefox */
.ant-table-wrapper {
  scrollbar-width: thin;
  // scrollbar-color: #4caf50 #f1f1f1;
}

/* For Internet Explorer and Edge */
.ant-table-wrapper {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.white-theme {
  $tableblue: #2a3f54;
  $gray-100: rgba(255, 255, 255, 0.05);
  .custom-table-1 .ant-table-thead .ant-table-row-expand-icon-cell {
    // background: $tableblue !important;
    border-radius: 0px !important;
  }
  .domains-card {
    background-color: rgb(228 228 228) !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
}

.odd-row {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.white-theme {
  .even-row {
    background-color: #ffffff !important;
  }
  .addBatch-LabelColor {
    color: #000;
  }
  .table-head1 {
    background: #b6b6b6;
  }
  .a-label-headling {
    color: #000 !important;
  }
  .a-label-value {
    color: #000 !important;
  }
  hr:not([size]).black-hr {
    background: #c6c7c8 !important;
  }
  .custom-table-1 tr:nth-child(odd) td {
    background-color: #fff !important;
    padding: 0px 3px !important;
    border: 1px solid #c6c7c8 !important;
  }
  .custom-table-1 tr:nth-child(even) td {
    border: 1px solid #c6c7c8 !important;
    padding: 0px 3px !important;
  }
  .map-modal {
    background: rgb(229 229 229);
  }
  .ant-table-content table {
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  .ant-table-content td {
    border: 1px solid #c6c7c8 !important;
    // color: #acacac !important;
    font-weight: 900 !important;
    font-size: 12px !important;
  }
  .chatbox svg {
    fill: white !important;
  }
  .table-colaps {
    border: 1px solid #c6c7c8 !important;
    margin: 5px;
  }
  .thread-hunting-table {
    border-bottom: 1px solid #c6c7c8 !important;
    padding-bottom: 10px;

    // border-right: 1px solid #c6c7c8 !important;
  }

  .table-colaps .configure-inner-table-border {
    padding: 10px;
    border: 1px solid #c6c7c8;
  }
  .table-colaps .ant-collapse-item {
    border: 1px solid #c6c7c8;
  }
  .table-colaps .ant-collapse-item:nth-child(odd) {
    background-color: #fff !important;
  }
  .gm-style-iw-c {
    background: #e5e5e5 !important;
  }
}

.dark-theme {
  .addBatch-LabelColor {
    color: rgba(255, 255, 255, 0.4);
  }
}

.blue-theme {
  .addBatch-LabelColor {
    color: rgba(255, 255, 255, 0.4);
  }
}
